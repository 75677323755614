import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Title from '../../Title';
import FormDialog from '../FormDialogs';
import ScrollDialog from '../ScrollDialog';
import InterpretationFormDialog from "../FormDialogs/InterpretationFormDialog";

const SectionRenderer = (props) => {
  const {
    classes, slideMode, featureList, title, openDialog, setOpenDialog, openOfferForm, setOfferForm, formOrder, openRegisterForm, setRegisterForm,
  } = props;


  return (
    <div className={classNames(classes.feature, slideMode ? classes.mono : classes.color)}>
      <div className={!slideMode ? classes.container : ''}>
        <Title title={title} align="left" />
        <Grid container className={classes.root} spacing={1}>
          {featureList.map(item => (
            <Grid className={classes.highWidth} key={item.id.toString()} item xs={12} md={10}>
              {item.title && (
                <Typography component="h4" variant="h6">
                  {item.title}
                </Typography>
              )}
              <Typography component="div" className={slideMode ? classes.colorWhite : ''}>
                {item.desc}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <FormDialog formOrder={openOfferForm ? 'translations' : formOrder} openedForm={openOfferForm ? 'translationQuotes' : ''} switchForm={setOfferForm} />
        {openRegisterForm && (
          <InterpretationFormDialog formOrder="notary" openedForm="notaryOfficeRegister" switchForm={setRegisterForm} />
        )}
      </div>
      <ScrollDialog
        open={openDialog}
        setOpen={setOpenDialog}
      />
    </div>
  );
};
SectionRenderer.propTypes = {
  classes: PropTypes.object.isRequired,
  slideMode: PropTypes.bool,
  featureList: PropTypes.arrayOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func.isRequired,
  openOfferForm: PropTypes.bool,
  setOfferForm: PropTypes.func,
  formOrder: PropTypes.string.isRequired,
  openRegisterForm: PropTypes.bool,
  setRegisterForm: PropTypes.func,
};
SectionRenderer.defaultProps = {
  slideMode: false,
  openDialog: false,
  openOfferForm: false,
  openRegisterForm: false,
  setOfferForm: () => {},
  setRegisterForm: () => {},
};


export default SectionRenderer;
