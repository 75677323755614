import produce from 'immer';
import {
  FORM_REQUEST,
  FORM_SUCCESS,
  FORM_FAILURE,
  GET_PARAMETERS_REQUEST,
  GET_PARAMETERS_SUCCESS,
  GET_PARAMETERS_FAILURE,
  GET_LANGUAGE_CODES_REQUEST,
  GET_LANGUAGE_CODES_SUCCESS,
  GET_LANGUAGE_CODES_FAILURE,
  GET_ENVIRONMENT_REQUEST,
  GET_ENVIRONMENT_SUCCESS,
  GET_ENVIRONMENT_FAILURE,
  GET_SWORN_CODES_REQUEST,
  GET_SWORN_CODES_SUCCESS,
  GET_SWORN_CODES_FAILURE,
  GET_GENDER_REQUEST,
  GET_GENDER_SUCCESS,
  GET_GENDER_FAILURE,
} from '../actions/Types/ActionTypes';


export const initialState = {
  requestLoading: false,
  message: null,
  parametersLoading: false,
  parameters: null,
  languageCodesLoading: false,
  languageCodes: null,
  gendersLoading: false,
  genders: null,
  env: null,
  envLoading: false,
  swornCodesLoading: false,
  swornCodes: null,
};

/* eslint-disable default-case, no-param-reassign */
const languageProviderReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case FORM_REQUEST:
      draft.requestLoading = true;
      draft.message = null;
      break;
    case FORM_SUCCESS:
      draft.requestLoading = false;
      break;
    case FORM_FAILURE:
      draft.requestLoading = false;
      draft.message = action.payload.response.data.message;
      break;
    case GET_PARAMETERS_REQUEST:
      draft.parametersLoading = true;
      draft.parameters = null;
      break;
    case GET_PARAMETERS_SUCCESS:
      draft.parametersLoading = false;
      draft.parameters = action.payload;
      break;
    case GET_PARAMETERS_FAILURE:
      draft.parametersLoading = false;
      draft.parameters = null;
      break;
    case GET_LANGUAGE_CODES_REQUEST:
      draft.languageCodesLoading = true;
      draft.languageCodes = null;
      break;
    case GET_LANGUAGE_CODES_SUCCESS:
      draft.languageCodesLoading = false;
      draft.languageCodes = action.payload;
      break;
    case GET_LANGUAGE_CODES_FAILURE:
      draft.languageCodesLoading = false;
      draft.languageCodes = null;
      break;
    case GET_SWORN_CODES_REQUEST:
      draft.swornCodesLoading = true;
      draft.swornCodes = null;
      break;
    case GET_SWORN_CODES_SUCCESS:
      draft.swornCodesLoading = false;
      draft.swornCodes = action.payload;
      break;
    case GET_SWORN_CODES_FAILURE:
      draft.swornCodesLoading = false;
      draft.swornCodes = null;
      break;
    case GET_GENDER_REQUEST:
      draft.gendersLoading = true;
      draft.genders = null;
      break;
    case GET_GENDER_SUCCESS:
      draft.gendersLoading = false;
      draft.genders = action.payload;
      break;
    case GET_GENDER_FAILURE:
      draft.gendersLoading = false;
      draft.genders = null;
      break;
    case GET_ENVIRONMENT_REQUEST:
      draft.envLoading = true;
      draft.env = null;
      break;
    case GET_ENVIRONMENT_SUCCESS:
      draft.envLoading = false;
      draft.env = action.payload;
      break;
    case GET_ENVIRONMENT_FAILURE:
      draft.envLoading = false;
      draft.env = null;
  }
});

export default languageProviderReducer;
