import {
  FORM_REQUEST,
  FORM_SUCCESS,
  FORM_FAILURE,
  GET_PARAMETERS_REQUEST,
  GET_PARAMETERS_SUCCESS,
  GET_PARAMETERS_FAILURE,
  GET_LANGUAGE_CODES_REQUEST,
  GET_LANGUAGE_CODES_SUCCESS,
  GET_LANGUAGE_CODES_FAILURE,
  GET_ENVIRONMENT_REQUEST,
  GET_ENVIRONMENT_SUCCESS,
  GET_ENVIRONMENT_FAILURE,
  GET_SWORN_CODES_REQUEST,
  GET_SWORN_CODES_SUCCESS,
  GET_SWORN_CODES_FAILURE, GET_GENDER_REQUEST, GET_GENDER_SUCCESS, GET_GENDER_FAILURE,
} from './Types/ActionTypes';

export function createFormSubmitRequest(payload, env) {
  return {
    type: FORM_REQUEST,
    payload,
    config: env,
  };
}

export function createFormSubmitSuccess(payload) {
  return {
    type: FORM_SUCCESS,
    payload
  };
}
export function createFormSubmitFailure(payload) {
  return {
    type: FORM_FAILURE,
    payload
  };
}

export function getParametersRequest(env) {
  return {
    type: GET_PARAMETERS_REQUEST,
    config: env,
  };
}

export function getParametersSuccess(payload) {
  return {
    type: GET_PARAMETERS_SUCCESS,
    payload
  };
}
export function getParametersFailure(payload) {
  return {
    type: GET_PARAMETERS_FAILURE,
    payload
  };
}

export function getLanguageCodesRequest(env) {
  return {
    type: GET_LANGUAGE_CODES_REQUEST,
    config: env,
  };
}

export function getLanguageCodesSuccess(payload) {
  return {
    type: GET_LANGUAGE_CODES_SUCCESS,
    payload
  };
}
export function getLanguageCodesFailure(payload) {
  return {
    type: GET_LANGUAGE_CODES_FAILURE,
    payload
  };
}

export function getEnvironmentRequest() {
  return {
    type: GET_ENVIRONMENT_REQUEST,
  };
}

export function getEnvironmentSuccess(payload) {
  return {
    type: GET_ENVIRONMENT_SUCCESS,
    payload
  };
}
export function getEnvironmentFailure(payload) {
  return {
    type: GET_ENVIRONMENT_FAILURE,
    payload
  };
}
export function getSwornCodesRequest(env) {
  return {
    type: GET_SWORN_CODES_REQUEST,
    config: env,
  };
}

export function getSwornCodesSuccess(payload) {
  return {
    type: GET_SWORN_CODES_SUCCESS,
    payload
  };
}
export function getSwornCodesFailure(payload) {
  return {
    type: GET_SWORN_CODES_FAILURE,
    payload
  };
}

export function getGenderRequest(env) {
  return {
    type: GET_GENDER_REQUEST,
    config: env,
  };
}

export function getGenderSuccess(payload) {
  return {
    type: GET_GENDER_SUCCESS,
    payload
  };
}
export function getGenderFailure(payload) {
  return {
    type: GET_GENDER_FAILURE,
    payload
  };
}
