const carouselData = [
  {
    background: '#83adb1',
    title: 'carousel.cbr.title',
    desc: 'carousel.cbr.desc',
    icon: 'directions_car',
    path: '#cbr-tolkdiensten',
    notaryButton: false,
    cbrButton: true
  },
  {
    background: '#162d42',
    title: 'carousel.notary.title',
    desc: 'carousel.notary.desc',
    icon: 'chat',
    path: '#notaristolken',
    notaryButton: true,
    cbrButton: false
  },
  {
    background: '#355069',
    title: 'carousel.translation.title',
    desc: 'carousel.translation.desc',
    icon: 'assignment',
    path: '#vertaalwerkzaamheden',
    notaryButton: false,
    cbrButton: false
  },
  {
    background: '#92a4b9',
    title: 'carousel.course.title',
    desc: 'carousel.course.desc',
    icon: 'school',
    path: '#overige-tolkdiensten',
    notaryButton: false,
    isComingSoon: true,
    cbrButton: false
  }
];

export default carouselData;
