export const getIsRequestLoading = (state) => state.getIn(['formState']).requestLoading;
export const getMessage = (state) => state.getIn(['formState']).message;
export const getIsParametersLoading = (state) => state.getIn(['formState']).parametersLoading;
export const getParameters = (state) => state.getIn(['formState']).parameters;
export const getIsLanguageCodesLoading = (state) => state.getIn(['formState']).languageCodesLoading;
export const getLanguageCodes = (state) => state.getIn(['formState']).languageCodes;
export const getGenders = (state) => state.getIn(['formState']).genders;
export const getIsGendersLoading = (state) => state.getIn(['formState']).gendersLoading;
export const getEnvLoading = (state) => state.getIn(['formState']).envLoading;
export const getEnv = () => process.env;
export const getIsSwornCodesLoading = (state) => state.getIn(['formState']).swornCodesLoading;
export const getSwornCodes = (state) => state.getIn(['formState']).swornCodes;
export const getLocale = (state) => state.getIn(['language']).locale;
