import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import brand from 'dan-api/dummy/brand';
import Tooltip from '@material-ui/core/Tooltip';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { bindActionCreators } from 'redux';
import { ThemeContext } from '../../containers/App/ThemeWrapper';
import SideNavMobile from './SideNavMobile';
import ScrollDialog from './components/ScrollDialog';
import {
  getIsParametersLoading,
  getParameters,
  getIsLanguageCodesLoading,
  getLanguageCodes,
  getEnv,
  getEnvLoading,
  getIsSwornCodesLoading,
  getSwornCodes,
  getLocale,
  getIsGendersLoading, getGenders,
} from './redux/selectors/selector';
import {
  getLanguageCodesRequest,
  getParametersRequest,
  getEnvironmentRequest,
  getSwornCodesRequest, getGenderRequest,
} from './redux/actions/actions';
import formatMessage from './components/helpers';

import styles from './landingStyle-jss';


let counter = 0;
function createData(name, url) {
  counter += 1;
  return {
    id: counter,
    name,
    url,
  };
}

function Header(props) {
  const {
    classes,
    turnDarker,
    mode,
    getLang,
    getParams,
    paramsLoading,
    langLoading,
    lang,
    genders,
    gendersLoading,
    env,
    envLoading,
    getSworn,
    getGender,
    swornCodes,
    swornLoading,
    parameters,
  } = props;

  const changeMode = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const reducer = 'ui';
  const locale = useSelector(getLocale);
  const gradient = useSelector(state => state.getIn([reducer, 'gradient']));

  const menuList = [
    createData(formatMessage(locale, 'cbrServices.title.main'), '#cbr-tolkdiensten'),
    createData(formatMessage(locale, 'notaryInterpreter.title.main'), '#notaristolken'),
    createData(formatMessage(locale, 'translation.title.main'), '#vertaalwerkzaamheden'),
    createData(formatMessage(locale, 'contact.title.main'), '#contact'),
  ];

  useEffect(() => {
    if (env !== null && !paramsLoading && parameters === null) {
      getParams(env);
    }
  }, [paramsLoading]);
  useEffect(() => {
    if (env !== null && !langLoading && lang === null) {
      getLang(env);
    }
    if (env !== null && !paramsLoading && parameters === null) {
      getParams(env);
    }
    if (env !== null && !swornLoading && swornCodes === null) {
      getSworn(env);
    }
    if (env !== null && !gendersLoading && genders === null) {
      getGender(env);
    }
  }, [envLoading]);

  useEffect(() => {
    if (env !== null && !langLoading && lang === null) {
      getLang(env);
    }
  }, [langLoading]);

  useEffect(() => {
    if (env !== null && !swornLoading && swornCodes === null) {
      getSworn(env);
    }
  }, [swornLoading]);

  useEffect(() => {
    if (env !== null && !gendersLoading && genders === null) {
      getGender(env);
    }
  }, [gendersLoading]);

  const turnMode = () => {
    if (mode === 'light') {
      changeMode('dark');
    } else {
      changeMode('light');
    }
  };
  const toggleDrawerOpen = () => {
    setOpen(true);
  };

  const toggleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ScrollDialog
        open={openDialog}
        setOpen={setOpenDialog}
      />
      <Hidden lgUp>
        <SwipeableDrawer
          onClose={toggleDrawerClose}
          onOpen={toggleDrawerOpen}
          open={open}
          anchor="left"
        >
          <SideNavMobile menuList={menuList} closeDrawer={toggleDrawerClose} />
          <Tooltip title="Lichte/donkere modus" placement="bottom">
            <IconButton className={classes.button} onClick={() => turnMode(mode)}>
              <i className="ion-ios-bulb-outline" />
            </IconButton>
          </Tooltip>
          {Array.isArray(parameters) && (
            <Tooltip title="Bel één van onze medewerkers" placement="bottom">

              <IconButton
                className={classes.button}
                onClick={() => {
                  window.open(`tel:${parameters.find(item => item.paramName === 'MASTERTOLKEN_PHONE').paramValue}`);
                }}
              >
                <i className="ion-ios-call-outline" />
                <span style={{ fontSize: '14px', fontWeight: '600', marginLeft: '5px' }}>{parameters.find(item => item.paramName === 'MASTERTOLKEN_PHONE').paramValue}</span>
              </IconButton>

            </Tooltip>
          )}
          <Button size="small" onClick={() => setOpenDialog(true)}>{formatMessage(locale, 'terms.and.conditions')}</Button>
        </SwipeableDrawer>
      </Hidden>
      <AppBar
        className={
          classNames(
            classes.header,
            turnDarker && classes.darker,
            gradient ? classes.gradient : classes.solid
          )
        }
      >
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            aria-label="Menu"
            onClick={toggleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div className={classes.container}>
          <div className={classes.spaceContainer}>
            <NavLink
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                });
              }}
              to="/"
              className={classes.brand}
            >
              {brand.name}
            </NavLink>
            <Hidden mdDown>
              <nav>
                <Scrollspy items={['cbr-tolkdiensten', 'notaristolken', 'vertaalwerkzaamheden', 'overige-tolkdiensten', 'contact']} currentClassName="active">
                  {menuList.map(item => (
                    <li key={item.id.toString()}>
                      <Button component={AnchorLink} href={item.url}>{item.name}</Button>
                    </li>
                  ))}
                  <Tooltip title="Lichte/donkere modus" placement="bottom">
                    <IconButton className={classes.button} onClick={() => turnMode(mode)}>
                      <i className="ion-ios-bulb-outline" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Bel één van onze medewerkers" placement="bottom">
                    <IconButton onClick={() => window.open(`tel:${Array.isArray(parameters) && parameters.find(item => item.paramName === 'MASTERTOLKEN_PHONE').paramValue}`)}>
                      <i className="ion-ios-call-outline" />
                      <span style={{ fontSize: '14px', fontWeight: '400' }}>{Array.isArray(parameters) && parameters.find(item => item.paramName === 'MASTERTOLKEN_PHONE').paramValue}</span>
                    </IconButton>
                  </Tooltip>
                </Scrollspy>
              </nav>
            </Hidden>
          </div>
        </div>
      </AppBar>
    </>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  turnDarker: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  paramsLoading: PropTypes.bool.isRequired,
  langLoading: PropTypes.bool.isRequired,
  lang: PropTypes.arrayOf(PropTypes.object),
  getLang: PropTypes.func.isRequired,
  gendersLoading: PropTypes.bool.isRequired,
  genders: PropTypes.arrayOf(PropTypes.object),
  getGender: PropTypes.func.isRequired,
  getParams: PropTypes.func.isRequired,
  env: PropTypes.any,
  envLoading: PropTypes.bool.isRequired,
  swornLoading: PropTypes.bool.isRequired,
  swornCodes: PropTypes.arrayOf(PropTypes.object),
  getSworn: PropTypes.func.isRequired,
  parameters: PropTypes.arrayOf(PropTypes.any),
};

Header.defaultProps = {
  params: null,
  lang: null,
  env: null,
  swornCodes: null,
  parameters: null,
  genders: null,
};
const reducer = 'ui';
const mapStateToProps = state => ({
  mode: state.getIn([reducer, 'type']),
  paramsLoading: getIsParametersLoading(state),
  parameters: getParameters(state),
  langLoading: getIsLanguageCodesLoading(state),
  lang: getLanguageCodes(state),
  genders: getGenders(state),
  gendersLoading: getIsGendersLoading(state),
  env: getEnv(state),
  envLoading: getEnvLoading(state),
  swornLoading: getIsSwornCodesLoading(state),
  swornCodes: getSwornCodes(state),
  ...state,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getLang: getLanguageCodesRequest,
  getSworn: getSwornCodesRequest,
  getGender: getGenderRequest,
  getParams: getParametersRequest,
  loadEnv: getEnvironmentRequest,
}, dispatch);
const HeaderMapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

export default withStyles(styles)(HeaderMapped);
