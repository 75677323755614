module.exports = {
  translationRequest: '/rest/translations/requests',
  translationQuotes: '/rest/translations/quotes',
  notaryOfficeRegister: '/rest/businesses',
  interpretationRequest: '/rest/interpretations/requests/notary',
  interpretationQuotes: '/rest/interpretations/quotes/notary',
  interpretationQuotesOther: '/rest/interpretations/quotes/local-tolk',
  createContact: '/rest/contacts/create',
  parameters: '/rest/parameters',
  codeLibraries: '/rest/code-libraries'
};
