import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import '../FormDialog.styles.scss';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DateFnsUtils from '@date-io/date-fns';
import nlLocale from 'date-fns/locale/nl';
import MaterialDropZone from '../MaterialDropZone';
import { createFormSubmitRequest } from '../../redux/actions/actions';
import {
  getIsRequestLoading,
  getMessage,
  getLanguageCodes,
  getEnv,
  getEnvLoading,
  getSwornCodes,
  getLocale,
} from '../../redux/selectors/selector';
import StyledNotif from '../StyledNotif';
import formatMessage from '../helpers';

const styles = theme => ({
  demo: {
    height: 'auto',
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  picker: {
    margin: '0px 5px',
  },
  uploadPicker: {
    margin: '8px 5px',
  },
  picker2: {
    width: '230px',
    margin: '0px 5px',
  },
  field: {
    margin: '0px 5px',
    width: '210px'
  },
  longField: {
    margin: '0px 5px',
    width: '490px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttonContainer: {
    textAlign: 'center',
    alignItems: 'center'
  },
  button: {
    display: 'inline-block',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    float: 'center',
  },
  centeredButton: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    float: 'right',
    width: '200px'
  }
});

function TranslationFormDialog(props) {
  const [open, setOpen] = useState(false);
  const [formMode, setFormMode] = useState('translationQuotes');
  const [selectedDate, setSelectedDate] = useState(moment().add(1, 'day'));
  const [translationType, setTranslationType] = useState(null);
  const [language, setLanguage] = useState(null);
  const [targetLanguage, setTargetLanguage] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedTargetLanguage, setSelectedTargetLanguage] = useState(null);
  const [referenceNo, setReferenceNo] = useState(null);
  const [notaryOffice, setNotaryOffice] = useState(null);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [street, setStreet] = useState(null);
  const [houseNo, setHouseNo] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [city, setCity] = useState(null);
  const [wordCount, setWordCount] = useState(null);

  const [languageError, setLanguageError] = useState(false);
  const [targetLanguageError, setTargetLanguageError] = useState(false);
  const [referenceNoError, setReferenceNoError] = useState(false);
  const [notaryOfficeError, setNotaryOfficeError] = useState(false);
  const [wordCountError, setWordCountError] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [streetError, setStreetError] = useState(false);
  const [houseNoError, setHouseNoError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [cityError, setCityError] = useState(false);

  const [isFormEnabled, setFormEnabled] = useState(false);

  const [comments, setComments] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filePath, setFilePath] = useState(null);

  const resetFormData = () => {
    setSelectedDate(moment().add(1, 'day'));
    setTranslationType(null);
    setLanguage(null);
    setTargetLanguage(null);
    setReferenceNo(null);
    setNotaryOffice(null);
    setName(null);
    setEmail(null);
    setPhone(null);
    setStreet(null);
    setHouseNo(null);
    setPostalCode(null);
    setCity(null);
    setComments(null);
    setWordCount(null);
    setFilesToUpload([]);
    setSelectedLanguage(null);
    setSelectedTargetLanguage(null);
    setFilePath(null);
  };

  useEffect(() => {
    if (!open) {
      resetFormData();
    }
  }, [open]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleLanguageChange = event => {
    setLanguage(event.target.value);
  };
  const handleTargetLanguageChange = event => {
    setTargetLanguage(event.target.value);
  };

  const handleSelectedLanguageChange = event => {
    setSelectedLanguage(event.target.value);
  };
  const handleSelectedTargetLanguageChange = event => {
    setSelectedTargetLanguage(event.target.value);
  };
  const handleReferenceNoChange = event => {
    setReferenceNo(event.target.value);
  };
  const handleNotaryOfficeChange = event => {
    setNotaryOffice(event.target.value);
  };
  const handleNameChange = event => {
    setName(event.target.value);
  };
  const handleEmailChange = event => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = event => {
    setPhone(event.target.value);
  };
  const handleStreetChange = event => {
    setStreet(event.target.value);
  };
  const handleHouseNoChange = event => {
    setHouseNo(event.target.value);
  };
  const handlePostalCodeChange = event => {
    setPostalCode(event.target.value);
  };
  const handleCityChange = event => {
    setCity(event.target.value);
  };
  const handleCommentsChange = event => {
    setComments(event.target.value);
  };

  const handleTranslationTypeChange = event => {
    setTranslationType(event.target.value);
  };

  const handleWordCountChange = (event) => {
    if (event.target.value === '') {
      setWordCount(null);
    } else {
      setWordCount(event.target.value);
    }
  };
  const updatePath = () => {
    let fileNames = '';
    Object.keys(filesToUpload).forEach((fileIndex) => {
      fileNames = filesToUpload[fileIndex].name + ',' + fileNames;
    });
    setFilePath(fileNames);
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const newFiles = filesToUpload;
    const fileCount = files.length;
    for (let i = 0; i < fileCount; i += 1) {
      newFiles[filesToUpload.length + i] = files[i];
    }
    updatePath();
    setFilesToUpload(newFiles);
  };


  const {
    classes,
    submitForm,
    openedForm,
    switchForm,
    isRequestLoading,
    requestMessage,
    languageCodes,
    env,
    envLoading,
    swornCodes,
    locale,
  } = props;

  const reference = useRef(isRequestLoading);

  useEffect(() => {
    if (reference.current !== isRequestLoading && !isRequestLoading && requestMessage !== null) {
      setShowNotification(true);
      setNotificationType('error');
      setNotificationMessage(requestMessage);
    } else if (reference.current !== isRequestLoading && !isRequestLoading && requestMessage === null) {
      setShowNotification(true);
      setNotificationType('success');
      setNotificationMessage(formatMessage(locale, 'forms.notification.success'));
      setOpen(false);
    }
    reference.current = isRequestLoading;
  }, [isRequestLoading]);

  useEffect(() => {
    if (!open && openedForm !== '') {
      setOpen(true);
      setFormMode(openedForm);
    } else {
      switchForm(false);
    }
  }, [openedForm]);

  const handleClickOpen = (form) => {
    setFormMode(form);
    setOpen(true);
  };

  const handleClose = () => {
    if (openedForm !== '') {
      switchForm(false);
    }
    setOpen(false);
  };

  const numberRegex = new RegExp('^[0-9]+$');
  const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;

  const checkValidity = () => {
    setNameError(name !== null && name === '');
    setCityError(city !== null && city === '');
    setStreetError(street !== null && !street);
    setPostalCodeError(postalCode !== null && !postalCode);
    setLanguageError(language !== null && !language);
    setTargetLanguageError(targetLanguage !== null && !targetLanguage);
    setReferenceNoError(referenceNo !== null && !referenceNo);
    setNotaryOfficeError(notaryOffice !== null && !notaryOffice);
    setHouseNoError(houseNo !== null && !houseNo);
    if (email) {
      setEmailError(!emailRegex.test(email));
    }

    if (phone && phone !== '') {
      setPhoneError(!numberRegex.test(phone));
    } else if (phone === '') {
      setPhoneError(true);
    }
    if (wordCount) {
      setWordCountError(!numberRegex.test(wordCount));
    } else {
      setWordCountError(false);
    }
  };
  const translationsValidation = () => (name && !nameError
  && city && !cityError
  && street && !streetError
  && postalCode && !postalCodeError
  && (selectedLanguage !== null) && (selectedLanguage === 'xx' ? language && !languageError : true)
  && selectedTargetLanguage !== null && (selectedTargetLanguage === 'xx' ? targetLanguage && !targetLanguageError : true)
  && (selectedLanguage === 'xx' && selectedTargetLanguage === 'xx') ? language !== targetLanguage : selectedLanguage !== selectedTargetLanguage
    && email && !emailError
    && phone && !phoneError
    && houseNo && !houseNoError
    && (wordCount ? !wordCountError : true)
    && (notaryOffice ? !notaryOfficeError : true)
    && referenceNo && !referenceNoError
    && filesToUpload !== null
    && translationType !== null
    && env !== null && !envLoading);

  const formContent = {
    translationQuotes: {
      title: formatMessage(locale, 'forms.translationQuotes.title'),
      comments: formatMessage(locale, 'forms.translationQuotes.comments'),
      confirm: formatMessage(locale, 'forms.translationQuotes.confirm'),
      translationType: {
        label: formatMessage(locale, 'forms.translationType.label'),
      },
      sourceLanguage: {
        label: formatMessage(locale, 'forms.sourceLanguage.label'),
      },
      targetLanguage: {
        label: formatMessage(locale, 'forms.targetLanguage.label'),
      },
      referenceNo: {
        label: formatMessage(locale, 'forms.referenceNo.label'),
      },
      date: {
        label: formatMessage(locale, 'forms.upload.date.label'),
      },
      upload: true,
      notaryOffice: {
        label: formatMessage(locale, 'forms.requester.label'),
        required: false,
      },
      showReference: true,
      requestType: 'translationQuotes',
      requestDataGenerator: () => ({
        sourceLanguageCode: selectedLanguage,
        sourceCustomLanguage: language,
        targetCustomLanguage: targetLanguage,
        targetLanguageCode: selectedTargetLanguage,
        wordCount,
        translationType,
        deliveryDate: moment(selectedDate).format('DD-MM-yyyy'),
        companyName: notaryOffice,
        referenceNo,
        contactInfo: {
          name,
          email,
          phone,
        },
        deliveryAddress: {
          street,
          houseNo,
          postalCode,
          city
        },
        comments
      }),
    },
    translationRequest: {
      title: formatMessage(locale, 'forms.translationRequest.title'),
      comments: formatMessage(locale, 'forms.translationRequest.comments'),
      confirm: formatMessage(locale, 'forms.translationRequest.confirm'),
      translationType: {
        label: formatMessage(locale, 'forms.translationType.label'),
      },
      sourceLanguage: {
        label: formatMessage(locale, 'forms.sourceLanguage.label'),
      },
      targetLanguage: {
        label: formatMessage(locale, 'forms.targetLanguage.label'),
      },
      referenceNo: {
        label: formatMessage(locale, 'forms.referenceNo.label'),
      },
      date: {
        label: formatMessage(locale, 'forms.upload.date.label'),
      },
      upload: true,
      notaryOffice: {
        label: formatMessage(locale, 'forms.requester.label'),
        required: false,
      },
      showReference: true,
      requestType: 'translationRequest',
      requestDataGenerator: () => ({
        sourceLanguageCode: selectedLanguage,
        sourceCustomLanguage: language,
        targetCustomLanguage: targetLanguage,
        targetLanguageCode: selectedTargetLanguage,
        wordCount,
        translationType,
        deliveryDate: moment(selectedDate).format('DD-MM-yyyy'),
        companyName: notaryOffice,
        referenceNo,
        contactInfo: {
          name,
          email,
          phone,
        },
        deliveryAddress: {
          street,
          houseNo,
          postalCode,
          city
        },
        comments
      }),
    },
  };

  useEffect(() => {
    checkValidity();
    setFormEnabled(translationsValidation());
  }, [name, city, street, postalCode, language, targetLanguage, email, phone, houseNo, wordCount, notaryOffice, referenceNo, translationType, env, envLoading]);

  useEffect(() => {
    setFormEnabled(translationsValidation());
  }, [nameError, cityError, streetError, postalCodeError, languageError, targetLanguageError, emailError, phoneError, houseNoError, wordCountError, notaryOfficeError, referenceNoError]);


  const handleConfirm = () => {
    const JSONData = formContent[formMode].requestDataGenerator();
    submitForm({
      json: JSONData,
      upload: true,
      requestType: formContent[formMode].requestType,
      files: filesToUpload,
    }, env);
  };

  const handleNotary = () => {
    if (!envLoading && env && process.env.NOTARY_URL) {
      window.open(process.env.NOTARY_URL, '_blank');
    }
  };

  return (
    <div>
      <div className={classes.buttonContainer}>
        <Button variant="contained" className={classes.button} color="secondary" onClick={handleNotary}>
          {formatMessage(locale, 'buttons.notary.label')}
        </Button>
        <Button variant="contained" className={classes.button} color="secondary" onClick={() => handleClickOpen('translationQuotes')}>
          {formatMessage(locale, 'buttons.offer.label')}
        </Button>
        <Button variant="contained" className={classes.button} color="secondary" onClick={() => handleClickOpen('translationRequest')}>
          {formatMessage(locale, 'buttons.translationRequest.label')}
        </Button>
      </div>
      <Dialog maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{formContent[formMode].title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="translation-type">{formContent[formMode].translationType.label}</InputLabel>
              <Select
                autocomplete
                value={translationType}
                onChange={handleTranslationTypeChange}
                inputProps={{
                  name: formContent[formMode].translationType.label,
                  id: 'translation-type',
                }}
              >
                { Array.isArray(swornCodes) && (swornCodes.map((option, index) => (
                  <MenuItem key={index.toString()} value={option.value}>{option.description}</MenuItem>
                )))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="translation-type">{formContent[formMode].sourceLanguage.label}</InputLabel>
              <Select
                autocomplete
                value={selectedLanguage}
                onChange={handleSelectedLanguageChange}
                inputProps={{
                  name: formContent[formMode].translationType.label,
                  id: 'translation-type',
                }}
              >
                {Array.isArray(languageCodes) && languageCodes.map((option, index) => (
                  <MenuItem key={index.toString()} value={option.value}>{option.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
            { selectedLanguage === 'xx' && (
              <div className={classes.field}>
                <TextField
                  value={language}
                  autoComplete="tel-extension"
                  error={languageError}
                  margin="dense"
                  id="referenceNo"
                  label={formContent[formMode].sourceLanguage.label}
                  type="text"
                  required
                  fullWidth
                  onChange={handleLanguageChange}
                />
              </div>
            )}
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="translation-type">{formContent[formMode].targetLanguage.label}</InputLabel>
              <Select
                autocomplete
                value={selectedTargetLanguage}
                onChange={handleSelectedTargetLanguageChange}
                inputProps={{
                  name: formContent[formMode].translationType.label,
                  id: 'translation-type',
                }}
              >
                {Array.isArray(languageCodes) && languageCodes.map((option, index) => (
                  <MenuItem key={index.toString()} value={option.value}>{option.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
            { selectedTargetLanguage === 'xx' && (
              <div className={classes.field}>
                <TextField
                  value={targetLanguage}
                  autoComplete="tel-extension"
                  error={targetLanguageError}
                  margin="dense"
                  id="referenceNo"
                  label={formContent[formMode].targetLanguage.label}
                  type="text"
                  required
                  fullWidth
                  onChange={handleTargetLanguageChange}
                />
              </div>
            )}
          </Grid>
          <Grid container spacing={1}>
            <div className={classes.field}>
              <TextField
                value={referenceNo}
                autoComplete="tel-extension"
                error={referenceNoError}
                margin="dense"
                id="referenceNo"
                label={formContent[formMode].referenceNo.label}
                type="text"
                required
                fullWidth
                onChange={handleReferenceNoChange}
              />
            </div>

            <div className={classes.uploadPicker}>
              <MuiPickersUtilsProvider locale={nlLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disablePast
                  label={formContent[formMode].date.label}
                  value={selectedDate}
                  onChange={handleDateChange}
                  format="dd-MM-yyyy"
                  cancelLabel={formatMessage(locale, 'picker.cancel.label')}
                  okLabel={formatMessage(locale, 'picker.ok.label')}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
          <div>
            <MaterialDropZone
              accept="*"
              onChange={handleFileChange}
              multiple
              filepath={filePath}
              label={formatMessage(locale, 'forms.fileInput.button.label')}
            />
            <TextField
              value={wordCount}
              error={wordCountError}
              autoComplete="tel-extension"
              margin="dense"
              id="wordCount"
              label={formatMessage(locale, 'forms.wordCount.label')}
              type="tel"
              fullWidth
              onChange={handleWordCountChange}
            />
          </div>
          <TextField
            value={notaryOffice}
            autoComplete="name organization"
            margin="dense"
            id="notaryOffice"
            label={formContent[formMode].notaryOffice.label}
            type="text"
            fullWidth
            required={formContent[formMode].notaryOffice.required}
            error={formContent[formMode].notaryOffice.required && notaryOfficeError}
            onChange={handleNotaryOfficeChange}
          />
          <Grid container spacing={1}>
            <div className={classes.longField}>
              <TextField
                value={street}
                error={streetError}
                autoComplete="street-address address-line1"
                margin="dense"
                id="street"
                label={formatMessage(locale, 'forms.street.label')}
                type="text"
                required
                fullWidth
                onChange={handleStreetChange}
              />
            </div>
            <div className={classes.field}>
              <TextField
                value={houseNo}
                autoComplete="tel-extension"
                error={houseNoError}
                margin="dense"
                id="houseNo"
                label={formatMessage(locale, 'forms.houseNo.label')}
                type="text"
                required
                fullWidth
                onChange={handleHouseNoChange}
              />
            </div>
          </Grid>
          <Grid container spacing={1}>
            <div className={classes.longField}>
              <TextField
                value={city}
                error={cityError}
                autoComplete="address-level2"
                margin="dense"
                id="city"
                label={formatMessage(locale, 'forms.city.label')}
                type="text"
                required
                fullWidth
                onChange={handleCityChange}
              />
            </div>
            <div className={classes.field}>
              <TextField
                value={postalCode}
                error={postalCodeError}
                autoComplete="postal-code"
                margin="dense"
                id="postalCode"
                label={formatMessage(locale, 'forms.postalCode.label')}
                type="text"
                required
                fullWidth
                onChange={handlePostalCodeChange}
              />
            </div>
          </Grid>
          <TextField
            value={name}
            error={nameError}
            autoComplete="name organization"
            margin="dense"
            id="contactName"
            label={formatMessage(locale, 'forms.contactName.label')}
            type="text"
            fullWidth
            required
            onChange={handleNameChange}
          />
          <TextField
            value={email}
            error={emailError}
            autoComplete="email"
            margin="dense"
            id="contactEmail"
            label={formatMessage(locale, 'forms.contactEmail.label')}
            type="email"
            fullWidth
            required
            onChange={handleEmailChange}
          />
          <TextField
            value={phone}
            autoComplete="tel"
            error={phoneError}
            margin="dense"
            id="contactTelno"
            label={formatMessage(locale, 'forms.contactTelno.label')}
            type="tel"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            fullWidth
            required
            onChange={handlePhoneChange}
          />
          <TextField
            value={comments}
            margin="dense"
            id="comments"
            label={formContent[formMode].comments}
            type="text"
            multiline
            fullWidth
            onChange={handleCommentsChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {formatMessage(locale, 'forms.cancel.label')}
          </Button>
          <Button onClick={handleConfirm} disabled={!isFormEnabled} color="primary">
            {formContent[formMode].confirm}
          </Button>
        </DialogActions>
        {showNotification && notificationType === 'error' && (
          <StyledNotif type={notificationType} message={notificationMessage} onClose={() => setShowNotification(false)} />
        ) }
      </Dialog>
      {showNotification && notificationType !== 'error' && (
        <StyledNotif type={notificationType} message={notificationMessage} onClose={() => setShowNotification(false)} />
      ) }
    </div>
  );
}

TranslationFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  submitForm: PropTypes.func.isRequired,
  openedForm: PropTypes.string.isRequired,
  switchForm: PropTypes.func.isRequired,
  isRequestLoading: PropTypes.bool.isRequired,
  requestMessage: PropTypes.string,
  languageCodes: PropTypes.arrayOf(PropTypes.any),
  env: PropTypes.any,
  envLoading: PropTypes.bool.isRequired,
  swornCodes: PropTypes.arrayOf(PropTypes.object),
  locale: PropTypes.string.isRequired,
};

TranslationFormDialog.defaultProps = {
  requestMessage: null,
  languageCodes: [],
  env: null,
  swornCodes: null,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  submitForm: createFormSubmitRequest,
}, dispatch);
const mapStateToProps = (state) => ({
  isRequestLoading: getIsRequestLoading(state),
  requestMessage: getMessage(state),
  languageCodes: getLanguageCodes(state),
  env: getEnv(state),
  envLoading: getEnvLoading(state),
  swornCodes: getSwornCodes(state),
  locale: getLocale(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TranslationFormDialog));
