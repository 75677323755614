/* eslint-disable no-plusplus */
import axios from 'axios';
import endpoints from './constants/endpoints';


const formAPI = ({ payload }) => axios.post(`${process.env.API_DOMAIN}${endpoints[payload.requestType]}`, payload.data, {
  headers: {
    'X-API-KEY': process.env.API_KEY
  }
});
export const contactAPI = ({ payload }) => axios.post(`${process.env.API_DOMAIN}${endpoints.createContact}`, payload.data, {
  headers: {
    'X-API-KEY': process.env.API_KEY
  }
});

export const fileUploadFormAPI = ({ payload }) => {
  const formData = new window.FormData();
  Object.keys(payload.files).forEach((fileIndex) => {
    formData.append('files', payload.files[fileIndex]);
  });
  formData.append('command', new window.Blob([JSON.stringify(payload.json)], { type: 'application/json' }));
  return axios.post(`${process.env.API_DOMAIN}${endpoints[payload.requestType]}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-API-KEY': process.env.API_KEY
    }
  });
};

export const getParametersAPI2 = async () => {
  const response = await axios.get(`${process.env.API_DOMAIN}${endpoints.parameters}`, {
    headers: {
      'X-API-KEY': process.env.API_KEY
    }
  });
  return response;
};

export const getParametersAPI = () => axios.get(`${process.env.API_DOMAIN}${endpoints.parameters}`, {
  headers: {
    'X-API-KEY': process.env.API_KEY
  }
});

export const getCodeLibrariesAPI = ({ params }) => axios.get(`${process.env.API_DOMAIN}${endpoints.codeLibraries}`, {
  params,
  headers: {
    'X-API-KEY': process.env.API_KEY
  }
});

export const getEnvironment = () => axios.get('/getEnvironment');


export default formAPI;
