import { IntlProvider } from 'react-intl';
import i18n from '../../../i18n';

const formatMessage = (locale, id) => {
  const intlProvider = new IntlProvider({ locale, messages: i18n.translationMessages[locale] });
  const { intl } = intlProvider.getChildContext();
  return intl.formatMessage({ id });
};

export default formatMessage;
