import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isString from 'lodash/fp/isString';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { getLocale } from '../redux/selectors/selector';
import formatMessage from './helpers';
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    color: 'white',
  },
  filename: {
    paddingLeft: '5px',
    cursor: 'pointer',
    width: '60%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '25px',
  },
  validation: {
    color: '#f44336',
    marginTop: '5px',
  },
}));

const acceptVariants = {
  word:
      '.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  images: 'image/*',
  excel:
      '.xlsx,.xls,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: '.csv',
};

const makeAcceptString = (accept) => {
  if (!accept || !accept.length) {
    return '';
  }

  if (isString(accept)) {
    return acceptVariants[accept] ? acceptVariants[accept] : accept;
  }

  return '';
};

const FileInput = ({
  accept,
  multiple = false,
  id = 'file-input',
  inputProps,
  labelProps,
  buttonProps,
  onChange,
  label = 'Add File',
  filepath,
}) => {
  const classes = useStyles();
  const locale = useSelector(getLocale);

  const acceptString = useMemo(() => makeAcceptString(accept), [accept]);

  const [path, setPath] = useState(filepath || formatMessage(locale, 'forms.fileInput.default.label'));

  const inputRef = useRef(null);
  useEffect(() => {
    if (filepath) {
      setPath(filepath);
    }
  }, [filepath]);

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        <input
          id={id}
          accept={acceptString}
          className={classes.input}
          multiple={multiple}
          type="file"
          onChange={onChange}
          ref={inputRef}
          {...inputProps}
        />
        <label className={classes.label} htmlFor={id} {...labelProps}>
          <Button
            variant="contained"
            color="secondary"
            component="span"
            className={classes.button}
            {...buttonProps}
          >
            {label}
          </Button>
          <span className={classes.filename} title={path}>
            {path.split(',').map((item) => (
              <div>
                {item}
              </div>

            ))}
          </span>
        </label>

      </FormControl>
    </div>
  );
};

FileInput.propTypes = {
  accept: PropTypes.string.isRequired,
  filepath: PropTypes.string,
  multiple: PropTypes.bool.isRequired,
  id: PropTypes.string,
  inputProps: PropTypes.objectOf(PropTypes.any),
  labelProps: PropTypes.objectOf(PropTypes.any),
  buttonProps: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

FileInput.defaultProps = {
  filepath: null,
  id: 'file-input',
  inputProps: null,
  labelProps: null,
  buttonProps: null,
};

export default FileInput;
