import React from 'react';
import Button from '@material-ui/core/Button';
import formatMessage from '../helpers';

const formatters = {
  currency: new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }),
};

export const notaryContent = (setOpen, setOfferForm, setRegisterForm, parameters) => (
  <div>
    <div style={{
      margin: '0cm', lineHeight: 'normal', marginBottom: '8.0pt'
    }}
    >
      <span>MasterTolken is dé taalspecialist bij de notaris. Wilt u belangrijke zaken regelen met cliënten die niet goed Nederlands spreken? Onze beëdigde tolken schuiven graag bij u aan op kantoor. Een telefonische of digitale afspraak kan natuurlijk ook.&nbsp;</span>
    </div>
    <div style={{
      margin: '0cm', lineHeight: 'normal', marginBottom: '8.0pt'
    }}
    >
      <strong><span>Hoe wij werken</span></strong>
      <span>
        <br />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                U kiest de datum, tijd en gewenste taal. Wij helpen u en uw cliënt om de akte(n) te bespreken of passeren. Maak gemakkelijk een afspraak via ons <a
                  href={process.env.NOTARY_URL} target="_blank" rel="noopener noreferrer"
                  style={{ color: 'black', textDecoration: 'none' }}
        ><strong>MasterPortaal</strong>
        </a>, onze website of vraag vrijblijvend een offerte aan.&nbsp;
        <br />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                Heeft u nog geen account? Registreer <strong onClick={() => setRegisterForm(true)}>hier</strong>.
      </span>
    </div>
    <div style={{
      margin: '0cm', lineHeight: 'normal', marginBottom: '8.0pt'
    }}
    >
      <strong><span>Kosten*</span></strong>
    </div>
    <ul style={{ listStyleType: 'disc', marginLeft: '26px' }}>
      <li key={1}>
        <span style={{ marginLeft: '6px' }}>Eén of twee akten en een afspraak van maximaal één uur:&nbsp;</span>
        <strong><span>{formatters.currency.format(Array.isArray(parameters) && parameters.find(item => item.paramName === 'INTERPRETATION_ONE_OR_TWO_DEEDS_PRICE').paramValue)},-</span></strong>
        <span>&nbsp;</span>
      </li>
      <li key={2}>
        <span style={{ marginLeft: '6px' }}>Drie akten en een afspraak van maximaal anderhalf uur:&nbsp;</span>
        <span>{formatters.currency.format(Array.isArray(parameters) && parameters.find(item => item.paramName === 'INTERPRETATION_THREE_DEEDS_PRICE').paramValue)},-</span>
      </li>
      <li key={3}>
        <span style={{ marginLeft: '6px' }}>Vier of vijf akten en een afspraak van maximaal twee uur:&nbsp;</span>
        <span>{formatters.currency.format(Array.isArray(parameters) && parameters.find(item => item.paramName === 'INTERPRETATION_FOUR_OR_FIVE_DEEDS_PRICE').paramValue)},-&nbsp;</span>
      </li>
    </ul>
    <div style={{
      margin: '0cm', lineHeight: 'normal'
    }}
    >
      <span style={{ fontSize: '16px' }}>&nbsp;</span>
    </div>
    <div style={{
      margin: '0cm', lineHeight: 'normal', marginBottom: '8.0pt'
    }}
    >
      <span>* Alle prijzen zijn exclusief btw.</span>
    </div>
    <div style={{
      margin: '0cm', lineHeight: 'normal', marginBottom: '8.0pt'
    }}
    >
      <span>In uitzonderlijke gevallen kan het tarief hoger uitkomen, bijvoorbeeld vanwege de combinatie van uw locatie en de gevraagde taal.&nbsp;</span>
    </div>
    <div style={{
      margin: '0cm', lineHeight: 'normal', marginBottom: '8.0pt'
    }}
    >
      <span>
De prijzen zijn inclusief voorbereidingstijd en reiskosten van de tolk. Loopt de afspraak een kwartier uit, dan rekenen wij geen extra kosten. Duurt de afspraak langer, dan brengen we
        <br />
        {formatters.currency.format(Array.isArray(parameters) && parameters.find(item => item.paramName === 'INTERPRETATION_FINE_PER_HALF_HOUR').paramValue)},- per half uur in rekening.
        <br />
          &nbsp;
      </span>
    </div>
    <div style={{
      margin: '0cm', lineHeight: 'normal', marginBottom: '8.0pt'
    }}
    >
      <span>Bij annulering gelden de volgende tarieven:&nbsp;</span>
    </div>
    <ul style={{ listStyleType: 'disc', marginLeft: '26px' }}>
      <li key={4}><span
        style={{ marginLeft: '6px' }}
      >Twee werkdagen van tevoren: {Array.isArray(parameters) && parameters.find(item => item.paramName === 'INTERPRETATION_CANCEL_RATE_TWO_DAYS').paramValue} procent van het factuurbedrag
      </span>
      </li>
      <li key={5}><span
        style={{ marginLeft: '6px' }}
      >Eén werkdag van tevoren: {Array.isArray(parameters) && parameters.find(item => item.paramName === 'INTERPRETATION_CANCEL_RATE_ONE_DAY').paramValue} procent van het factuurbedrag
      </span>
      </li>
      <li key={6}>
        <span style={{ marginLeft: '6px' }}>
Op de dag zelf: het hele factuurbedrag
          <br />
          <br />
            &nbsp;
        </span>
      </li>
    </ul>
    <div>
      <span style={{ lineHeight: '115%' }}>
        <div style={{ background: '#355069', padding: '25px' }}>
          <span style={{ color: '#fff' }}>
Laat bij MasterTolken ook uw aktes, brieven of andere documenten vertalen.
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <strong
              style={{ fontStyle: 'bold' }}
              onClick={() => setOfferForm(true)}
            >Vraag een vrijblijvende offerte
            </strong>
            {' '}
              aan en zie wat we voor u kunnen betekenen.&nbsp;
          </span>
        </div>
        <br />
        <br />
        {' '}
          Op alle tolk- en vertaaldiensten zijn onze
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <strong onClick={() => setOpen(true)}> algemene voorwaarden &nbsp;</strong>
van toepassing. &nbsp;
      </span>
    </div>
  </div>
);

export const translationContent = (setOpen, setRegisterForm, parameters) => (
  <div>
    <div style={{
      margin: '0cm', lineHeight: 'normal', marginBottom: '8.0pt'
    }}
    >
      <span>Heeft u of uw cliënt een vertaling nodig? Ook daar kunnen wij mee helpen. We werken uitsluitend met beëdigde vertalers, dus uw privacygevoelige documenten zijn bij ons in goede handen. We gaan discreet te werk, ook als u kiest voor een onbeëdigde vertaling.
        <br />
          Doe gemakkelijk een
aanvraag voor een vertaling via ons <a
  href={process.env.NOTARY_URL} target="_blank" rel="noopener noreferrer"
  style={{
            color: 'black',
            textDecoration: 'none'
          }}
        ><strong>MasterPortaal</strong>
        </a>.
        <br />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          Heeft u nog geen account? Registreer <strong onClick={() => setRegisterForm(true)}>hier</strong>.
      </span>
    </div>
    <div style={{
      margin: '0cm', lineHeight: 'normal', marginBottom: '8.0pt'
    }}
    >
      <strong>
        <span>
          <br />
Kosten*
        </span>
      </strong>
    </div>
    <ul style={{ listStyleType: 'disc', marginLeft: '26px' }}>
      <li key={7}>
        <span style={{ marginLeft: '6px' }}>Voor vertalingen geldt een minimumtarief van&nbsp;</span>
        <strong><span>{formatters.currency.format(Array.isArray(parameters) && parameters.find(item => item.paramName === 'INTERPRETATION_FINE_PER_HALF_HOUR').paramValue)},-</span></strong>
      </li>
      <li key={8}>
        <span style={{ marginLeft: '6px' }}>Onbeëdigde vertaling: vanaf&nbsp;</span>
        <strong><span>€ {Array.isArray(parameters) && parameters.find(item => item.paramName === 'TRANSLATION_UNSWORN_PRICE_PER_WORD').paramValue} per woord</span></strong>
      </li>
      <li key={9}>
        <span style={{ marginLeft: '6px' }}>Beëdigde vertaling: vanaf&nbsp;</span>
        <strong><span>€ {Array.isArray(parameters) && parameters.find(item => item.paramName === 'TRANSLATION_SWORN_PRICE_PER_WORD').paramValue} per woord</span></strong>
      </li>
      <li key={10}>
        <span style={{ marginLeft: '6px' }}>Apostille:&nbsp;</span>
        <strong>
          {/* eslint-disable-next-line array-callback-return */}
          <span
            style={{}}
          >{formatters.currency.format(Array.isArray(parameters) && parameters.filter(item => item.paramName === 'APOSTILLE_BASE_PRICE' || item.paramName === 'APOSTILLE_PROFIT_AMOUNT' || item.paramName === 'PRICE_PER_APOSTILLE').reduce((total, item) => total + Number(item.paramValue), 0))},-&nbsp;
          </span>
        </strong>
        <span>extra</span>
      </li>
    </ul>
    <div style={{
      margin: '0cm', lineHeight: 'normal', marginBottom: '8.0pt'
    }}
    >
      <span>* Alle bedragen zijn exclusief btw. &nbsp; &nbsp; &nbsp;</span>
    </div>
    <div style={{
      margin: '0cm', lineHeight: 'normal', marginBottom: '8.0pt'
    }}
    >
      <span>
        <br />
          &nbsp;Vraag hieronder een vrijblijvende offerte aan. U ontvangt binnen één werkdag bericht.&nbsp;
      </span>
    </div>
    <div style={{
      margin: '0cm', lineHeight: 'normal',
    }}
    >
      <span>
Op alle tolk- en vertaaldiensten zijn onze
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <strong onClick={() => setOpen(true)}> algemene voorwaarden&nbsp;</strong>
van toepassing.
      </span>
    </div>
  </div>
);

export const cbrContent = (setOpen) => (
  <div>
    <div style={{
      margin: '0cm', lineHeight: 'normal'
    }}
    >
      <span>
      MasterTolken is door het Centraal Bureau Rijvaardigheidsbewijzen (CBR) aangesteld om
individuele theorie-examens en praktijkexamens te voorzien van een tolk. Indien u een
individueel theorie-examen wilt afleggen zonder de Nederlandse taal volledig te beheersen, kunt
u via deze pagina een tolk reserveren voor de volgende examen types:
        <br />
          &nbsp;
      </span>
    </div>
    <div style={{
      margin: '0cm', lineHeight: 'normal'
    }}
    >
      <ul style={{ listStyleType: 'disc', marginLeft: '26px' }}>
        <li key={7}>
          <span style={{ marginLeft: '6px' }}>BTH-I-T (Theorie-examen auto met tolk)</span>
        </li>
        <li key={9}>
          <span style={{ marginLeft: '6px' }}>ATH-I-T (Theorie-examen motor met tolk)</span>
        </li>
        <li key={10}>
          <span style={{ marginLeft: '6px' }}>AMTH-I-T (Theorie-examen bromfiets met tolk)</span>
        </li>
      </ul>
      <div style={{
        margin: '0cm', lineHeight: 'normal', marginBottom: '8.0pt'
      }}
      >
        <br /><strong><span>Werkwijze</span></strong>
      </div>

      <div style={{
        margin: '0cm', lineHeight: 'normal'
      }}
      >
        <span>
          <br />
      Na de reservering schakelen wij een tolk in voor uw examen bij het CBR. De tolk verschijnt op de afgesproken datum en tijdstip bij het CBR.
De kandidaat zal met bijstand van de tolk en een examinator van het CBR in een aparte ruimte plaatsnemen. De tolk zal de examenvragen ter plekke vertalen waarna de kandidaat deze kan beantwoorden.
          <br />
            &nbsp;
        </span>
      </div>

      <strong><span>Praktische zaken</span></strong>

      <div style={{
        margin: '0cm', lineHeight: 'normal'
      }}
      >
        <span><br />De kosten voor de aanwezigheid van een tolk bij uw theorie-examen zijn&nbsp;
          <strong><span>226,- inclusief btw.</span></strong>
        </span>
      </div>
      <ul style={{ listStyleType: 'disc', marginLeft: '26px' }}>
        <li key={7}>
          <span style={{ marginLeft: '6px' }}>U kunt bij ons een tolk reserveren nadat u een reservering
            heeft gemaakt voor een individueel theorie-examen met tolk bij het CBR. Zie voor meer informatie <a
              href="https://www.cbr.nl/nl/veelgestelde-vragen/hoe-reserveer-ik-een-theorie-examen-met-tolk-1"
              target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'none' }}
          ><strong>de website van het CBR</strong>
          </a> .
          </span>
        </li>
        <li key={8}>
          <span style={{ marginLeft: '6px' }}>Na het invullen van het formulier wordt u doorgeschakeld naar een iDEAL-betaalscherm.</span>
        </li>
        <li key={9}>
          <span style={{ marginLeft: '6px' }}>Pas na betaling is de opdracht bevestigd en zullen wij een geschikte tolk toewijzen aan
    het examen. Alleen na betaling bent u zeker van uw reservering.
          </span>
        </li>
      </ul>

      <span>
        <br />
Op al onze diensten zijn onze
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <strong onClick={() => setOpen(true)}> algemene voorwaarden&nbsp;</strong>
van toepassing.&nbsp;
      </span>
    </div>
    <br />
    <div>
      <Button
        variant="contained"
        color="secondary"
        href={process.env.REACT_APP_CBR_IDEAL_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
                reserveer hier een tolk&nbsp;
      </Button>
    </div>
    <br />
    <div>
      <span style={{ lineHeight: '150%' }}>
        <div style={{ background: '#355069', padding: '25px' }}>
          <span style={{ color: '#fff' }}>
            <strong><span>Let op!</span></strong>
            <div style={{
              margin: '0cm', lineHeight: 'normal'
            }}
            >
              <ul style={{ listStyleType: 'disc', marginLeft: '26px' }}>
                <li key={1}>
                  <span style={{ marginLeft: '6px' }}>Sommige talen kunnen enkel op specifieke locaties worden geleverd. Controleer dit voor u uw individuele theorie-examen reserveert!</span>
                </li>
                <li key={2}>
                  <span style={{ marginLeft: '6px' }}>Wij hanteren een 14-dagen termijn: Er moet minimaal 14 dagen zitten tussen de
        aanvraagdatum en de datum van het werkelijke examen. Het formulier zal data binnen
        deze termijn dan ook niet toestaan.
                  </span>
                </li>
                <li key={3}>
                  <span
                    style={{ marginLeft: '6px' }}
                  >Betaling en daarmee het reserveren is enkel mogelijk door middel van iDEAL.
                  </span>
                </li>
                <li key={4}>
                  <span style={{ marginLeft: '6px' }}>Tot drie werkdagen voorafgaand aan het examen kunt u de tolk kosteloos annuleren.
        Hierna worden er kosten in rekening gebracht. Namelijk twee werkdagen van tevoren
        geldt een annuleringsvergoeding van €60,-, één werkdag van tevoren geldt €100,- en bij
        een annulering op de dag zelf wordt het volledige tarief in rekening gebracht.
                  </span>
                </li>
                <li key={5}>
                  <span style={{ marginLeft: '6px' }}>Het CBR heeft geen bemoeienis met het inschakelen van een tolk door een
        examenkandidaat. Indien de afspraak niet goed is vastgelegd en u op het examen
        verschijnt zonder een door ons aangewezen tolk, dan gaat het examen niet door.
                  </span>
                </li>
                <li key={6}>
                  <span style={{ marginLeft: '6px' }}>Bij een klassikaal theorie-examen is het niet toegestaan een (eigen) tolk mee te nemen.</span>
                </li>
                <li key={7}>
                  <span style={{ marginLeft: '6px' }}>Het reserveren van een tolk bij een CCV-examen kan uitsluitend per e-mail worden gedaan.</span>
                </li>
              </ul>
            </div>
            <br /><br />
            <span>Om u zo goed mogelijk te helpen, willen wij u verzoeken om ons bij vragen een mail te sturen op<a
              href="mailto:cbr@mastertolken.nl"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'black', textDecoration: 'none' }}
            ><strong> cbr@mastertolken.nl</strong>
            </a>
            </span>
          </span>
        </div>
      </span>
    </div>

    <div id="gtx-trans" style={{ position: 'absolute', left: '397px', top: '74px' }}>
      <div className="gtx-trans-icon"><br /></div>
    </div>
  </div>
);

export const otherServicesContent = (setOpen) => (
  <div>
    <div style={{
      margin: '0cm', lineHeight: 'normal'
    }}
    >
      <span>
Heeft u – digitaal of op locatie – een tolk nodig? Wij denken graag met u mee. Vraag een offerte aan en u hoort binnen 24 uur van ons.
        <br />
          &nbsp;
      </span>
    </div>
    <div style={{
      margin: '0cm', lineHeight: 'normal'
    }}
    >
      <span>
Op alle tolk- en vertaaldiensten zijn onze
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <strong onClick={() => setOpen(true)}> algemene voorwaarden&nbsp;</strong>
van toepassing.&nbsp;
      </span>
    </div>
    <div id="gtx-trans" style={{ position: 'absolute', left: '397px', top: '74px' }}>
      <div className="gtx-trans-icon"><br /></div>
    </div>
  </div>
);
