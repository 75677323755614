module.exports = {
  FORM_REQUEST: 'FORM_REQUEST',
  FORM_SUCCESS: 'FORM_SUCCESS',
  FORM_FAILURE: 'FORM_FAILURE',
  GET_PARAMETERS_REQUEST: 'GET_PARAMETERS_REQUEST',
  GET_PARAMETERS_SUCCESS: 'GET_PARAMETERS_SUCCESS',
  GET_PARAMETERS_FAILURE: 'GET_PARAMETERS_FAILURE',
  GET_LANGUAGE_CODES_REQUEST: 'GET_LANGUAGE_CODES_REQUEST',
  GET_LANGUAGE_CODES_SUCCESS: 'GET_LANGUAGE_CODES_SUCCESS',
  GET_LANGUAGE_CODES_FAILURE: 'GET_LANGUAGE_CODES_FAILURE',
  GET_ENVIRONMENT_REQUEST: 'GET_ENVIRONMENT_REQUEST',
  GET_ENVIRONMENT_SUCCESS: 'GET_ENVIRONMENT_SUCCESS',
  GET_ENVIRONMENT_FAILURE: 'GET_ENVIRONMENT_FAILURE',
  GET_SWORN_CODES_REQUEST: 'GET_SWORN_CODES_REQUEST',
  GET_SWORN_CODES_SUCCESS: 'GET_SWORN_CODES_SUCCESS',
  GET_SWORN_CODES_FAILURE: 'GET_SWORN_CODES_FAILURE',
  GET_GENDER_REQUEST: 'GET_GENDER_REQUEST',
  GET_GENDER_SUCCESS: 'GET_GENDER_SUCCESS',
  GET_GENDER_FAILURE: 'GET_GENDER_FAILURE',
};
