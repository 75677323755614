import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default function ScrollDialog(props) {
  const {
    open,
    setOpen
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const text = (
    <div>
      <p style={{ margin: '0in', }}><strong><span>Definitie</span></strong></p>
      <p style={{ margin: '0in', }}>
        <span>
Onder MasterTolken wordt in deze algemene voorwaarden verstaan Master Tolken B.V., gevestigd te Westzijde 83G, 1506 GA Zaandam, en ingeschreven in het handelsregister van de Kamer van Koophandel onder nummer 83244476.
          <br />
&nbsp;
        </span>
      </p>
      <p style={{ margin: '0in', }}><span>Onder opdrachtgever wordt in deze algemene voorwaarden verstaan elk natuurlijk- of rechtspersoon die opdracht geeft tot tolk- en/ of vertaaldiensten of aan wie MasterTolken in opdracht van deze een aanbod daartoe doet. &nbsp;</span></p>
      <p style={{ margin: '0in', }}><span>&nbsp;</span></p>
      <p style={{ margin: '0in', }}><span>Onder overeenkomst wordt in deze algemene voorwaarden verstaan iedere overeenkomst of opdracht tot dienstverlening tussen MasterTolken en opdrachtgever.&nbsp;</span></p>
      <p style={{ margin: '0in', }}><span>&nbsp;</span></p>
      <p style={{ margin: '0in', }}><strong><span>Artikel 1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Algemene bepalingen</span></strong></p>
      <p style={{ margin: '0in', }}><span>Deze algemene voorwaarden zijn van toepassing op alle rechtsbetrekkingen (waaronder aanbiedingen en offertes) tussen MasterTolken en opdrachtgever met uitsluiting van de door de opdrachtgever gehanteerde algemene voorwaarden.</span></p>
      <p style={{ margin: '0in', }}><span>&nbsp;</span></p>
      <p style={{ margin: '0in', }}><strong><span>Artikel 2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Totstandkoming van de overeenkomst</span></strong></p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>2.1&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;De aanbiedingen en offertes die MasterTolken verstrekt &nbsp;aan opdrachtgever zijn vrijblijvend.</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>2.2 &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Een overeenkomst komt pas tot stand nadat een opdracht schriftelijk is aanvaard door opdrachtgever.</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>2.3&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Aanbiedingen en offertes uit het verleden gelden niet automatisch voor toekomstige opdrachten tenzij anders overeengekomen. &nbsp;</span>
      </p>
      <p style={{ margin: '0in', }}><span>&nbsp;</span></p>
      <p style={{ margin: '0in', }}><strong><span>Artikel 3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Uitvoering van de overeenkomst</span></strong></p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>3.1&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;MasterTolken streeft ernaar de tolk- en vertaaldiensten zo goed mogelijk uit te voeren. Hierbij mag verwacht worden dat opdrachten naar beste weten en kunnen worden ingevuld en uitgevoerd.&nbsp;</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>3.2&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;MasterTolken kan opdrachtgever verzoeken tot (mondelinge) toelichting en het ter beschikking stellen van relevante documentatie ten behoeve van de tolk/ vertaler.&nbsp;</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>3.3&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;MasterTolken is te allen tijde vrij om zelf te bepalen welke tolk/ vertaler er ter beschikking wordt gesteld om de opdracht uit te voeren. De beschikbare tolk/ vertaler kan in aanloop naar de opdracht worden vervangen door een collega tolk/ vertaler met dezelfde kwalificaties. &nbsp;</span>
      </p>
      <p style={{ margin: '0in', }}><span>&nbsp;</span></p>
      <p style={{ margin: '0in', }}><strong><span>Artikel 4. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Wijziging en annulering&nbsp;</span></strong></p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>4.1&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Wijzigingen en annuleringen van de overeenkomsten, kunnen enkel schriftelijk worden ingediend.&nbsp;</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>4.2&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Bij een wijziging is MasterTolken gerechtigd de levertermijn of het factuurbedrag aan te passen of om de opdracht alsnog te weigeren. Eventuele gemaakte kosten zullen in het laatste geval worden doorberekend aan opdrachtgever.&nbsp;</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>4.3&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Mocht opdrachtgever de overeenkomst met betrekking tot de tolkdienst willen annuleren, zijn de annuleringstarieven van MasterTolken van toepassing zoals beschreven op de website tenzij de toegewezen tolk eigen tarieven hanteert.&nbsp;</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>4.4&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Mocht de opdrachtgever de overeenkomst met betrekking tot de vertaalwerkzaamheden willen annuleren, zal MasterTolken kosten in rekening brengen voor de reeds uitgevoerde werkzaamheden voor de opdracht.&nbsp;</span>
      </p>
      <p style={{ margin: '0in', }}><span>&nbsp;</span></p>
      <p style={{ margin: '0in', }}><span>&nbsp;</span></p>
      <p style={{ margin: '0in', }}><span>&nbsp;</span></p>
      <p style={{ margin: '0in', }}><span>&nbsp;</span></p>
      <p style={{ margin: '0in', }}><strong><span>Artikel 5. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Levering vertaalwerkzaamheden</span></strong></p>
      <p style={{ margin: '0in', }}><span>5.1&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Een overeengekomen leveringstermijn bij een vertaalopdracht is een streeftermijn.&nbsp;</span></p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>5.2&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Indien er geen leveringstermijn van een vertaalopdracht tussen MasterTolken en opdrachtgever is overeengekomen, heeft MasterTolken het recht deze naar redelijkheid te bepalen.&nbsp;</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>5.3&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Indien opdrachtgever een onbeëdigde vertaling heeft aangevraagd, zal deze uitsluitend digitaal (per mail) worden toegestuurd.</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>5.4&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Indien opdrachtgever een beëdigde vertaling heeft aangevraagd, zal deze digitaal (per mail) en per reguliere post worden verstuurd.</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>5.5&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Mocht opdrachtgever de vertaling middels een andere weg (aangetekende post of per koerier) willen ontvangen, komen deze kosten voor rekening van opdrachtgever tenzij anders overeengekomen.&nbsp;</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>5.6&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;MasterTolken heeft aan de levering voldaan op het moment van verzending. Voor eventuele vertraging in de postbezorging is MasterTolken niet verantwoordelijk.&nbsp;</span>
      </p>
      <p style={{ margin: '0in', }}><span>&nbsp;</span></p>
      <p style={{ margin: '0in', }}><strong><span>Artikel 6.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Prijzen en betaling</span></strong></p>
      <p style={{ margin: '0in', }}><span>6.1&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Alle prijzen zijn vermeld in euro’s, exclusief btw.&nbsp;</span></p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>6.2&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Opdrachtgever dient binnen dertig kalenderdagen na factuurdatum de betaling te verrichten, tenzij schriftelijk anders overeengekomen. &nbsp;</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>6.3&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Indien opdrachtgever niet tijdig betaalt, is MasterTolken gerechtigd buitengerechtelijke incassokosten in rekening te brengen. MasterTolken is hierbij gerechtigd eventuele andere overeenkomsten met opdrachtgever op te schorten.&nbsp;</span>
      </p>
      <p style={{ margin: '0in', }}><strong><span>&nbsp;</span></strong></p>
      <p style={{ margin: '0in', }}><strong><span>Artikel 7. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Aansprakelijkheidsbeperkingen</span></strong></p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>7.1&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;De aansprakelijkheid van MasterTolken is beperkt tot terugbetalen van het factuurbedrag, exclusief btw, indien dit bedrag voorafgaand aan de opdracht is betaald.</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>7.2&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;MasterTolken is niet aansprakelijk voor alle andere vormen van schade zoals indirecte schade, gevolgschade, bedrijfsschade, vertragingsschade en gederfde winst.&nbsp;</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>7.3&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Dubbelzinnigheid van de te vertalen tekst ontheft MasterTolken van iedere aansprakelijkheid en komt voor rekening en risico van opdrachtgever.&nbsp;</span>
      </p>
      <p style={{ margin: '0in', }}><strong><span>&nbsp;</span></strong></p>
      <p style={{ margin: '0in', }}><strong><span>Artikel 8. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Ontbinding en overmacht</span></strong></p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>8.1&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Indien</span>
        <span>&nbsp;opdrachtgever niet aan zijn verplichtingen voldoet, failliet verklaard is of diens faillissement wordt aangevraagd, surseance van betaling heeft aangevraagd of deze aan hem is verleend, indien ten aanzien van opdrachtgever de schuldsaneringsregeling natuurlijke personen van toepassing is verklaard of ingeval van liquidatie van het bedrijf van opdrachtgever, is MasterTolken, zonder tot enige schadevergoeding gehouden te zijn, bevoegd de overeenkomst geheel of gedeeltelijk te ontbinden dan wel de uitvoering daarvan op te schorten. MasterTolken kan alsdan onmiddellijke voldoening van het haar toekomende vorderen.</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>8.2 &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Indien MasterTolken door overmacht situaties die niet voor haar risico komen of die buiten haar macht liggen, niet meer aan de verplichten kan voldoen, heeft MasterTolken, zonder tot enige schadevergoeding gehouden te zijn, het recht de overeenkomst te ontbinden.&nbsp;</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>8.3&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Onder overmacht wordt verstaan elke omstandigheid die buiten het toedoen van MasterTolken is ontstaan, waardoor de normale uitvoering van de overeenkomst wordt verhinderd. Hieronder valt de onmogelijkheid de opdracht uit te voeren vanwege verhindering van door MasterTolken ingeschakelde derden, evenals onmogelijkheid voor onszelf ten gevolge van ziekte, stakingen, verstoringen van energievoorzieningen, verkeersstoornissen, stoornissen in transport en post en/ of telecommunicatie. &nbsp;</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>&nbsp;</span>
      </p>
      <p style={{ margin: '0in', }}><strong><span>&nbsp;</span></strong></p>
      <p style={{ margin: '0in', }}><strong><span>Artikel 9. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Klachtenafhandeling</span></strong></p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>9.1&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Opdrachtgever dient een klacht zo spoedig mogelijk maar uiterlijk 10 werkdagen na de geleverde dienst schriftelijk bij MasterTolken in te dienen. Het kenbaar maken van een klacht heeft geen invloed op de eerder overeengekomen betaaltermijn.&nbsp;</span>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>9.2&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Indien de klacht gegrond wordt verklaard door MasterTolken zal zij ervoor zorgen dat de geleverde dienst binnen redelijke tijd zal worden verbeterd of vervangen. Wanneer MasterTolken niet aan deze verbetering of deze vervanging kan voldoen, kan zij een reductie op de prijs verlenen.&nbsp;</span>
      </p>
      <p style={{ margin: '0in' }}><strong><span>&nbsp;</span></strong></p>
      <p style={{ margin: '0in' }}><strong><span>Artikel 10. &nbsp; &nbsp; &nbsp; &nbsp;Geheimhouding</span></strong></p>
      <p style={{ margin: '0in' }}><span>De (vertrouwelijk) informatie welke vrij komt bij het aangaan en/ of uitvoeren van de overeenkomst tussen opdrachtgever en MasterTolken zal te allen tijde vertrouwelijk worden behandeld. MasterTolken verplicht haar medewerkers en eventuele ingeschakelde derden tot geheimhouding.&nbsp;</span></p>
      <p style={{
        marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%',
      }}
      >
        <strong>
          <span>
            <br />
&nbsp;Artikel 11.&nbsp; &nbsp; &nbsp; &nbsp;Toepasselijk recht en bevoegde rechter
          </span>
        </strong>
      </p>
      <p style={{
        margin: '0in', marginLeft: '35.25pt', textIndent: '-35.25pt'
      }}
      >
        <span>11.1&nbsp; &nbsp; &nbsp; &nbsp;Op alle overeenkomsten tussen MasterTolken en opdrachtgever waarop deze algemene voorwaarden van toepassing zijn, alsmede de eventuele geschillen die daaruit voortvloeien is Nederlands recht van toepassing.</span>
      </p>
      <p style={{ margin: '0in', }}><span>11.2&nbsp; &nbsp; &nbsp; &nbsp;Geschillen zullen worden voorgelegd bij de daartoe bevoegde rechter te Amsterdam.&nbsp;</span></p>
      <p style={{ margin: '0in', }}><span>&nbsp;</span></p>
    </div>
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Algemene Voorwaarden</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {text}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
ScrollDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
ScrollDialog.defaultProps = {
  open: false,
  setOpen: () => {},
};
