import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CarouselWidget from './components/CarouselWidget';
import styles from './landingStyle-jss';
import BannerParallax from './BannerParallax';

function Banner(props) {
  const { classes, slideMode } = props;

  const reducer = 'ui';
  const gradient = useSelector(state => state.getIn([reducer, 'gradient']));
  return (
    <div
      className={
        classNames(
          classes.banner,
          gradient ? classes.gradient : classes.solid,
          slideMode ? classes.out : classes.fit
        )
      }
    >
      {!slideMode && <BannerParallax />}
      <div className={!slideMode ? classes.container : ''}>
        <Typography component="h2" variant="h2" gutterBottom>MasterTolken</Typography>

        <section>
          <CarouselWidget />
        </section>
      </div>
    </div>
  );
}

Banner.propTypes = {
  classes: PropTypes.object.isRequired,
  slideMode: PropTypes.bool
};

Banner.defaultProps = {
  slideMode: false
};

const MemoedBanner = memo(Banner);
export default (withStyles(styles)(MemoedBanner));
