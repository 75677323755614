import {
  call, put, takeLeading,
} from 'redux-saga/effects';
import formAPI, {
  contactAPI,
  fileUploadFormAPI,
  getParametersAPI,
  getCodeLibrariesAPI,
  getEnvironment,
} from '../api/api';
import {
  createFormSubmitSuccess,
  createFormSubmitFailure,
  getParametersSuccess,
  getParametersFailure,
  getLanguageCodesSuccess,
  getLanguageCodesFailure,
  getEnvironmentSuccess,
  getEnvironmentFailure,
  getSwornCodesSuccess,
  getSwornCodesFailure, getGenderSuccess, getGenderFailure,
} from '../actions/actions';
import {
  FORM_REQUEST,
  GET_PARAMETERS_REQUEST,
  GET_LANGUAGE_CODES_REQUEST,
  GET_ENVIRONMENT_REQUEST,
  GET_SWORN_CODES_REQUEST,
  GET_GENDER_REQUEST,
} from '../actions/Types/ActionTypes';

function* formSubmitWorker(action) {
  try {
    const api = action.payload.requestType ? (action.payload.upload ? fileUploadFormAPI : formAPI) : contactAPI;
    const res = yield call(api, { payload: action.payload, config: action.config });
    yield put(createFormSubmitSuccess(res.data));
  } catch (error) {
    yield put(createFormSubmitFailure(error));
  }
}

function* getParametersWorker(action) {
  try {
    const res = yield call(getParametersAPI, action.config);
    yield put(getParametersSuccess(res.data));
  } catch (error) {
    yield put(getParametersFailure(error));
  }
}

function* getLanguageCodesWorker(action) {
  try {
    const res = yield call(getCodeLibrariesAPI, { config: action.config, params: { codeGroup: 'LANGUAGE_CODE' } });
    yield put(getLanguageCodesSuccess(res.data));
  } catch (error) {
    yield put(getLanguageCodesFailure(error));
  }
}

function* getSwornCodesWorker(action) {
  try {
    const res = yield call(getCodeLibrariesAPI, { config: action.config, params: { codeGroup: 'SWORN_CODE' } });
    yield put(getSwornCodesSuccess(res.data));
  } catch (error) {
    yield put(getSwornCodesFailure(error));
  }
}

function* getGenderWorker(action) {
  try {
    const res = yield call(getCodeLibrariesAPI, { config: action.config, params: { codeGroup: 'GENDER' } });
    yield put(getGenderSuccess(res.data));
  } catch (error) {
    yield put(getGenderFailure(error));
  }
}

function* getEnvWorker() {
  try {
    const res = yield call(getEnvironment);
    yield put(getEnvironmentSuccess(res.data));
  } catch (error) {
    yield put(getEnvironmentFailure(error));
  }
}

function* formWatcher() {
  yield takeLeading(FORM_REQUEST, formSubmitWorker);
  yield takeLeading(GET_PARAMETERS_REQUEST, getParametersWorker);
  yield takeLeading(GET_LANGUAGE_CODES_REQUEST, getLanguageCodesWorker);
  yield takeLeading(GET_SWORN_CODES_REQUEST, getSwornCodesWorker);
  yield takeLeading(GET_GENDER_REQUEST, getGenderWorker);
  yield takeLeading(GET_ENVIRONMENT_REQUEST, getEnvWorker);
}
export default formWatcher;
