import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import brand from 'dan-api/dummy/brand';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  getLocale,
} from './redux/selectors/selector';
import styles from './landingStyle-jss';
import ScrollDialog from './components/ScrollDialog';
import formatMessage from './components/helpers';

let counter = 0;
function createData(name, url) {
  counter += 1;
  return {
    id: counter,
    name,
    url,
  };
}

function Decoration(props) {
  const { classes } = props;
  return (
    <div>
      <svg fill="#fff" className={classes.footerDecoration}>
        <use xlinkHref="/images/decoration/petal5.svg#Petal-Bottom" />
      </svg>
    </div>
  );
}

Decoration.propTypes = {
  classes: PropTypes.object.isRequired,
};

const DecorationStyled = withStyles(styles)(Decoration);

function Footer(props) {
  const { classes } = props;
  const locale = useSelector(getLocale);
  const [openDialog, setOpenDialog] = useState(false);
  const menuList = [
    createData(formatMessage(locale, 'notaryInterpreter.title.main'), '#notaristolken'),
    createData(formatMessage(locale, 'translation.title.main'), '#vertaalwerkzaamheden'),
    createData(formatMessage(locale, 'cbrServices.title.main'), '#cbr-tolkdiensten'),
    createData(formatMessage(locale, 'contact.title.main'), '#contact'),
  ];

  return (
    <footer className={classes.footer}>
      <DecorationStyled />
      <div className={classes.container}>
        <div className={classes.spaceContainer}>
          <div className={classes.brand}>
            {brand.name}
          </div>
          <nav>
            <ul>
              {menuList.map(item => (
                <li key={item.id.toString()}>
                  <Button size="small" href={item.url}>{item.name}</Button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
      <div className={classes.copyright}>
        <div className={classes.container}>
          <p>Powered by <a href="https://www.caunos.com/" style={{ textDecoration: 'none', color: 'red' }} target="_blank" rel="noopener noreferrer">Caunos</a></p>
          <p>&copy; {moment(new Date()).format('YYYY')} MasterTolken. All Right Reserved.</p>
          <Button size="small" onClick={() => setOpenDialog(true)}>{formatMessage(locale, 'terms.and.conditions')}</Button>
        </div>
      </div>
      <ScrollDialog
        open={openDialog}
        setOpen={setOpenDialog}
      />
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
