import React, { useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Icon from '@material-ui/core/Icon';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import { connect, useSelector } from 'react-redux';
import 'dan-styles/vendors/slick-carousel/slick-carousel.css';
import 'dan-styles/vendors/slick-carousel/slick.css';
import 'dan-styles/vendors/slick-carousel/slick-theme.css';
import { FormattedMessage } from 'react-intl';
import carouselData from './carouselData';
import styles from './widget-jss';
import FormDialog from './FormDialogs';
import {
  getEnv, getEnvLoading, getLocale, getParameters
} from '../redux/selectors/selector';
import formatMessage from './helpers';

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      className="nav-next"
      onClick={onClick}
    >
      <ArrowForward />
    </IconButton>
  );
}

SampleNextArrow.propTypes = {
  onClick: PropTypes.func,
};

SampleNextArrow.defaultProps = {
  onClick: undefined,
};

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      className="nav-prev"
      onClick={onClick}
    >
      <ArrowBack />
    </IconButton>
  );
}

SamplePrevArrow.propTypes = {
  onClick: PropTypes.func,
};

SamplePrevArrow.defaultProps = {
  onClick: undefined,
};

const formatters = {
  currency: new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }),
};


function CarouselWidget(props) {
  const { classes, envLoading, env } = props;
  const locale = useSelector(getLocale);
  const parameters = useSelector(getParameters);
  const [showForm, setShowForm] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    centerMode: false,
    speed: 500,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
    ],
    cssEase: 'ease-out',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const handleLanguageCourses = () => {
    if (!envLoading && env && process.env.LANGUAGE_COURSE_URL) {
      window.open(process.env.LANGUAGE_COURSE_URL, '_blank');
    }
  };

  const handleCbr = () => {
    if (env && process.env.REACT_APP_CBR_IDEAL_URL) {
      window.open(process.env.REACT_APP_CBR_IDEAL_URL, '_blank');
    }
  };

  const handleNotary = () => {
    if (env && process.env.NOTARY_URL) {
      window.open(process.env.NOTARY_URL, '_blank');
    }
  };

  return (
    <div className="container custom-arrow">
      <Slider {...settings}>
        {carouselData.map((item, index) => (
          <div key={index.toString()}>
            <div style={{ backgroundColor: item.background }} className={classes.carouselItem}>
              <Icon className={classes.iconBg}>{item.icon}</Icon>
              <Typography className={classes.carouselTitle} variant="subtitle1">
                {formatMessage(locale, item.title)}
              </Typography>
              <Typography className={classes.carouselDesc}>{item.desc === 'carousel.notary.desc' ? (
                <FormattedMessage
                  id={item.desc}
                  values={{ interpretationFee: formatters.currency.format(Array.isArray(parameters) && parameters.find(p => p.paramName === 'INTERPRETATION_ONE_OR_TWO_DEEDS_PRICE').paramValue) }}
                />
              ) : formatMessage(locale, item.desc)}
              </Typography>
              {item.isComingSoon ? (
                <Button variant="outlined" size="small" className={classes.buttonComingSoon} onClick={handleLanguageCourses}>
                  {formatMessage(locale, 'carousel.course.button')}
                </Button>
              ) : (
                <nav className={classes.carouselNav}>
                  <Scrollspy items={['cbr-tolkdiensten', 'notaristolken', 'vertaalwerkzaamheden', 'overige-tolkdiensten', 'contact']} currentClassName="active">
                    {item.notaryButton && (
                      <>
                        <Button variant="outlined" size="small" className={classes.buttonReadMore} onClick={() => { setShowForm(true); }}>
                          {formatMessage(locale, 'buttons.interpretationRequest.label')}
                        </Button>
                        <br />
                        <Button variant="outlined" size="small" className={classes.buttonReadMore} onClick={handleNotary}>
                          {formatMessage(locale, 'buttons.notary.label')}
                        </Button>
                      </>
                    )}
                    {item.cbrButton && (
                    <>
                      <Button variant="outlined" size="small" className={classes.buttonReadMore} onClick={handleCbr}>
                        {formatMessage(locale, 'buttons.interpretationRequest.label')}
                      </Button>
                    </>
                    )}
                    <br />
                    <Button variant="outlined" size="small" className={classes.buttonReadMore} component={AnchorLink} href={item.path}>
                      {formatMessage(locale, 'carousel.more.info')}
                    </Button>
                  </Scrollspy>
                </nav>
              )}
            </div>
          </div>
        ))}
      </Slider>
      {showForm && (
        <FormDialog formOrder="none" openedForm="interpretationRequest" switchForm={setShowForm} />
      )}

    </div>
  );
}

CarouselWidget.defaultProps = {
  env: null,
};

CarouselWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  env: PropTypes.any,
  envLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  env: getEnv(state),
  envLoading: getEnvLoading(state),
});
export default connect(mapStateToProps, null)(withStyles(styles)(CarouselWidget));
