import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReCAPTCHA from 'react-google-recaptcha';
import Title from './Title';
import styles from './landingStyle-jss';
import { createFormSubmitRequest } from './redux/actions/actions';
import {
  getIsRequestLoading,
  getMessage,
  getEnv,
  getEnvLoading,
  getLocale,
  getParameters,
} from './redux/selectors/selector';
import StyledNotif from './components/StyledNotif';
import formatMessage from './components/helpers';

function Contact(props) {
  const {
    classes, slideMode, submitForm, mode, isRequestLoading, requestMessage, envLoading, env, locale,
    parameters
  } = props;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [clientCaptchaRequest, setClientCaptchaRequest] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState(false);
  const onSubmit = () => {
    if (name !== '' && email !== '') {
      submitForm({
        data: {
          name,
          email,
          message,
          clientCaptchaRequest,
        }
      }, env);
    } else {
      setShowNotification(true);
      setNotificationType('error');
      setNotificationMessage(formatMessage(locale, 'contact.validation.notification'));
    }
  };
  const onChange = (captcha) => {
    setClientCaptchaRequest(captcha);
  };
  const clearData = () => {
    setName('');
    setEmail('');
    setMessage('');
    setClientCaptchaRequest(null);
  };

  const reference = useRef(isRequestLoading);

  useEffect(() => {
    if (reference.current !== isRequestLoading && !isRequestLoading && requestMessage !== null) {
      setShowNotification(true);
      setNotificationType('error');
      setNotificationMessage(requestMessage);
    } else if (reference.current !== isRequestLoading && !isRequestLoading && requestMessage === null) {
      setShowNotification(true);
      setNotificationType('success');
      setNotificationMessage(formatMessage(locale, 'contact.success.notification'));
      clearData();
    }
    reference.current = isRequestLoading;
  }, [isRequestLoading]);

  return (
    <div className={classNames(classes.contact, !slideMode && classes.withBg)}>
      {showNotification && (
        <StyledNotif type={notificationType} message={notificationMessage} onClose={() => setShowNotification(false)} />
      ) }
      <div className={classes.container}>
        <div className={classes.contactBubble}>
          <Title title="CONTACT" align="left" nomargin />
          <Typography component="p" className={classes.contactText}>Kunnen wij u nog ergens mee helpen? Stuur een bericht via onderstaand formulier, of bel ons tijdens kantooruren:<br />{Array.isArray(parameters) && parameters.find(item => item.paramName === 'MASTERTOLKEN_PHONE').paramValue}.</Typography>
          <Grid container spacing={3}>
            <Grid item lg={6} xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  label={formatMessage(locale, 'contact.form.name.label')}
                  className={classes.textField}
                  value={name}
                  required
                  onChange={e => setName(e.target.value)}
                  margin="normal"
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  id="standard-email"
                  label={formatMessage(locale, 'contact.form.email.label')}
                  className={classes.textField}
                  value={email}
                  required
                  onChange={e => setEmail(e.target.value)}
                  margin="normal"
                />
              </FormControl>
            </Grid>

            <Grid item lg={6} xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label={formatMessage(locale, 'contact.form.message.label')}
                  multiline
                  rows="4"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  className={classes.textField}
                  margin="normal"
                />
                {!envLoading && (
                  <ReCAPTCHA
                    id="captcha_container"
                    sitekey={process.env.ReCAPTCHA_KEY}
                    onChange={onChange}
                    theme={mode || 'light'}
                  />
                )
                }
              </FormControl>
              <div className={classes.btnArea}>
                <Button variant="contained" size="large" className={classes.button} color="secondary" onClick={onSubmit}>{formatMessage(locale, 'contact.form.submit.label')}</Button>
              </div>
            </Grid>
          </Grid>
          <div className={classes.address}>
            <p>{Array.isArray(parameters) && parameters.find(item => item.paramName === 'MASTERTOLKEN_ADDRESS_LINE_1').paramValue}, {Array.isArray(parameters) && parameters.find(item => item.paramName === 'MASTERTOLKEN_ADDRESS_LINE_2').paramValue}</p>
          </div>
          <div>
            <p>{Array.isArray(parameters) && parameters.find(item => item.paramName === 'MASTERTOLKEN_PHONE').paramValue}</p>
          </div>
          <div>
            <p>office@mastertolken.nl</p>
          </div>
        </div>
      </div>
    </div>
  );
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
  slideMode: PropTypes.bool,
  submitForm: PropTypes.func.isRequired,
  mode: PropTypes.string,
  isRequestLoading: PropTypes.bool.isRequired,
  requestMessage: PropTypes.any,
  env: PropTypes.any,
  envLoading: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  parameters: PropTypes.arrayOf(PropTypes.any),
};

Contact.defaultProps = {
  slideMode: false,
  mode: 'light',
  requestMessage: null,
  env: null,
  parameters: null,
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
  submitForm: createFormSubmitRequest,
}, dispatch);
const mapStateToProps = (state) => ({
  isRequestLoading: getIsRequestLoading(state),
  requestMessage: getMessage(state),
  env: getEnv(state),
  envLoading: getEnvLoading(state),
  locale: getLocale(state),
  parameters: getParameters(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Contact));
