import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { KeyboardDatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import '../FormDialog.styles.scss';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DateFnsUtils from '@date-io/date-fns';
import nlLocale from 'date-fns/locale/nl';
import { createFormSubmitRequest } from '../../redux/actions/actions';
import {
  getIsRequestLoading,
  getMessage,
  getLanguageCodes,
  getEnv,
  getEnvLoading,
  getLocale, getGenders,
} from '../../redux/selectors/selector';
import StyledNotif from '../StyledNotif';
import formatMessage from '../helpers';

const styles = theme => ({
  demo: {
    height: 'auto',
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  picker: {
    margin: '0px 5px',
  },
  uploadPicker: {
    margin: '8px 5px',
  },
  picker2: {
    width: '230px',
    margin: '0px 5px',
  },
  field: {
    margin: '0px 5px',
    width: '210px'
  },
  longField: {
    margin: '0px 5px',
    width: '490px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttonContainer: {
    textAlign: 'center',
    alignItems: 'center'
  },
  button: {
    display: 'inline-block',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    float: 'center',
  },
  centeredButton: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    float: 'right',
    width: '200px'
  },
  innerTitle: {
    marginLeft: -theme.spacing(3),
  },
});

function InterpretationFormDialog(props) {
  const [open, setOpen] = useState(false);
  const [formMode, setFormMode] = useState('interpretationQuotes');
  const [selectedDate, setSelectedDate] = useState(moment().add(1, 'day'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment('2000-01-01 09:00'));
  const [selectedEndTime, setSelectedEndTime] = useState(moment('2000-01-01 18:00'));
  const [language, setLanguage] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [referenceNo, setReferenceNo] = useState(null);
  const [notaryOffice, setNotaryOffice] = useState(null);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [street, setStreet] = useState(null);
  const [houseNo, setHouseNo] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [city, setCity] = useState(null);

  const [businessName, setBusinessName] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [initials, setInitials] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [employeeEmail, setEmployeeEmail] = useState(null);
  const [employeeMobileNo, setEmployeeMobileNo] = useState(null);

  const [languageError, setLanguageError] = useState(false);
  const [referenceNoError, setReferenceNoError] = useState(false);
  const [notaryOfficeError, setNotaryOfficeError] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [streetError, setStreetError] = useState(false);
  const [houseNoError, setHouseNoError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [cityError, setCityError] = useState(false);

  const [employeeEmailError, setEmployeeEmailError] = useState(false);
  const [employeeMobileNoError, setEmployeeMobileNError] = useState(false);
  const [businessNameError, setBusinessNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);


  const [isFormEnabled, setFormEnabled] = useState(false);

  const [comments, setComments] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState(null);


  const resetFormData = () => {
    setSelectedDate(moment().add(1, 'day'));
    setSelectedStartTime(moment('2000-01-01 09:00'));
    setSelectedEndTime(moment('2000-01-01 18:00'));
    setLanguage(null);
    setReferenceNo(null);
    setNotaryOffice(null);
    setName(null);
    setEmail(null);
    setPhone(null);
    setStreet(null);
    setHouseNo(null);
    setPostalCode(null);
    setCity(null);
    setComments(null);
    setSelectedLanguage(null);
    setInitials(null);
    setSelectedGender(null);
    setFirstName(null);
    setLastName(null);
    setEmployeeEmail(null);
    setEmployeeMobileNo(null);
    setBusinessName(null);
  };

  useEffect(() => {
    if (!open) {
      resetFormData();
    }
  }, [open]);
  useEffect(() => {
    if (!selectedStartTime) {
      setSelectedStartTime(moment('2000-01-01 09:00'));
    }
    if (!selectedEndTime) {
      setSelectedEndTime(moment('2000-01-01 18:00'));
    }
  }, [selectedStartTime, selectedEndTime]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleStartChange = (date) => {
    setSelectedStartTime(date);
  };
  const handleEndChange = (date) => {
    setSelectedEndTime(date);
  };
  const handleLanguageChange = event => {
    setLanguage(event.target.value);
  };

  const handleSelectedLanguageChange = event => {
    setSelectedLanguage(event.target.value);
  };

  const handleGenderChange = event => {
    setSelectedGender(event.target.value);
  };
  const handleReferenceNoChange = event => {
    setReferenceNo(event.target.value);
  };
  const handleNotaryOfficeChange = event => {
    setNotaryOffice(event.target.value);
  };
  const handleNameChange = event => {
    setName(event.target.value);
  };
  const handleEmailChange = event => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = event => {
    setPhone(event.target.value);
  };
  const handleStreetChange = event => {
    setStreet(event.target.value);
  };
  const handleHouseNoChange = event => {
    setHouseNo(event.target.value);
  };
  const handlePostalCodeChange = event => {
    setPostalCode(event.target.value);
  };
  const handleCityChange = event => {
    setCity(event.target.value);
  };
  const handleCommentsChange = event => {
    setComments(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmployeeEmailChange = (event) => {
    setEmployeeEmail(event.target.value);
  };

  const handleEmployeeMobileNoChange = (event) => {
    setEmployeeMobileNo(event.target.value);
  };

  const handleInitialChange = (event) => {
    setInitials(event.target.value);
  };

  const handleBusinessNameChange = (event) => {
    setBusinessName(event.target.value);
  };

  const {
    classes,
    formOrder,
    submitForm,
    openedForm,
    switchForm,
    isRequestLoading,
    requestMessage,
    languageCodes,
    genders,
    env,
    envLoading,
    locale,
  } = props;

  const reference = useRef(isRequestLoading);

  useEffect(() => {
    if (reference.current !== isRequestLoading && !isRequestLoading && requestMessage !== null) {
      setShowNotification(true);
      setNotificationType('error');
      setNotificationMessage(requestMessage);
    } else if (reference.current !== isRequestLoading && !isRequestLoading && requestMessage === null) {
      setShowNotification(true);
      setNotificationType('success');
      setNotificationMessage(formatMessage(locale, 'forms.notification.success'));
      setOpen(false);
      switchForm(false);
    }
    reference.current = isRequestLoading;
  }, [isRequestLoading]);

  useEffect(() => {
    if (!open && openedForm !== '') {
      setOpen(true);
      setFormMode(openedForm);
    } else {
      switchForm(false);
    }
  }, [openedForm]);

  const handleClickOpen = (form) => {
    setFormMode(form);
    setOpen(true);
  };

  const handleClose = () => {
    if (openedForm !== '') {
      switchForm(false);
    }
    setOpen(false);
  };

  const handleNotary = () => {
    if (!envLoading && env && process.env.NOTARY_URL) {
      window.open(process.env.NOTARY_URL, '_blank');
    }
  };

  const buttons = {
    notary: (
      <div className={classes.buttonContainer}>
        <Button key="notary-notary" variant="contained" className={classes.button} color="secondary" onClick={() => handleClickOpen('notaryOfficeRegister')}>
          {formatMessage(locale, 'buttons.notary.register.label')}
        </Button>
        <Button key="notary-notary" variant="contained" className={classes.button} color="secondary" onClick={handleNotary}>
          {formatMessage(locale, 'buttons.notary.label')}
        </Button>
        <Button key="notary-offer" variant="contained" className={classes.button} color="secondary" onClick={() => handleClickOpen('interpretationQuotes')}>
          {formatMessage(locale, 'buttons.offer.label')}
        </Button>
        <Button key="notary-request" variant="contained" className={classes.button} color="secondary" onClick={() => handleClickOpen('interpretationRequest')}>
          {formatMessage(locale, 'buttons.interpretationRequest.label')}
        </Button>
      </div>),
    otherServices: (
      <div className={classes.buttonContainer}>
        <Button key="other-offer" variant="contained" className={classes.button} color="secondary" onClick={() => handleClickOpen('interpretationQuotesOther')}>
          {formatMessage(locale, 'buttons.offer.label')}
        </Button>
      </div>),
    none: null,
  };

  const numberRegex = new RegExp('^[0-9]+$');
  const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;

  const checkValidity = () => {
    setNameError(name !== null && name === '');
    setCityError(city !== null && city === '');
    setStreetError(street !== null && !street);
    setPostalCodeError(postalCode !== null && !postalCode);
    setLanguageError(language !== null && !language);
    setReferenceNoError(referenceNo !== null && !referenceNo);
    setNotaryOfficeError(notaryOffice !== null && !notaryOffice);
    setHouseNoError(houseNo !== null && !houseNo);
    if (email) {
      setEmailError(!emailRegex.test(email));
    }

    if (phone && phone !== '') {
      setPhoneError(!numberRegex.test(phone));
    } else if (phone === '') {
      setPhoneError(true);
    }
  };

  const checkNotaryOfficeRegisterValidity = () => {
    setCityError(city !== null && city === '');
    setStreetError(street !== null && !street);
    setPostalCodeError(postalCode !== null && !postalCode);
    setBusinessNameError(businessName !== null && !businessName);
    setHouseNoError(houseNo !== null && !houseNo);
    setFirstNameError(firstName !== null && firstName === '');
    setLastNameError(lastName !== null && lastName === '');
    if (email) {
      setEmailError(!emailRegex.test(email));
    }

    if (phone && phone !== '') {
      setPhoneError(!numberRegex.test(phone));
    } else if (phone === '') {
      setPhoneError(true);
    }
    if (employeeEmail) {
      setEmployeeEmailError(!emailRegex.test(employeeEmail));
    }

    if (employeeMobileNo && employeeMobileNo !== '') {
      setEmployeeMobileNError(!numberRegex.test(employeeMobileNo));
    } else if (employeeMobileNo === '') {
      setPhoneError(true);
    }
  };

  const interpretationValidation = (isNotaryRequired) => (name && !nameError
      && city && !cityError
      && street && !streetError
      && postalCode && !postalCodeError
      && (selectedLanguage !== null) && (selectedLanguage === 'xx' ? language && !languageError : true)
      && email && !emailError
      && phone && !phoneError
      && houseNo && !houseNoError
      && (isNotaryRequired ? notaryOffice && !notaryOfficeError : true)
      && !envLoading
  );

  const notaryRegisterValidation = (isBusinessNameRequired) => (city && !cityError
      && street && !streetError
      && postalCode && !postalCodeError
      && email && employeeEmail && !emailError && !employeeEmailError
      && phone && employeeMobileNo && !phoneError && !employeeMobileNoError
      && houseNo && !houseNoError
      && (selectedGender !== null)
      && (isBusinessNameRequired ? businessName && !businessNameError : true)
      && firstName && !firstNameError
      && lastName && !lastNameError
      && env !== null && !envLoading
  );

  const formContent = {
    notaryOfficeRegister: {
      title: formatMessage(locale, 'forms.notaryOfficeRegister.title'),
      confirm: formatMessage(locale, 'buttons.notary.register.label'),
      business: {
        businessName: {
          label: formatMessage(locale, 'forms.notaryOffice.label'),
          required: true,
        },
        address: {
          street,
          houseNo,
          postalCode,
          city,
        },
        email,
        phone,
      },
      employee: {
        initials,
        firstName,
        lastName,
        selectedGender,
        employeeEmail,
        employeeMobileNo,
      },
      requestType: 'notaryOfficeRegister',
      requestDataGenerator: () => ({
        business: {
          businessName,
          address: {
            street,
            houseNo,
            postalCode,
            city,
          },
          email,
          phone,
        },
        employee: {
          initials,
          firstName,
          lastName,
          gender: selectedGender,
          email: employeeEmail,
          mobileNo: employeeMobileNo,
        },
      }),
    },
    interpretationQuotes: {
      title: formatMessage(locale, 'forms.interpretationQuotes.title'),
      comments: formatMessage(locale, 'forms.interpretationQuotes.comments'),
      confirm: formatMessage(locale, 'forms.interpretationQuotes.confirm'),
      pickers: {
        date: {
          label: formatMessage(locale, 'picker.date.label'),
        },
        start: {
          label: formatMessage(locale, 'picker.time.start.label'),
        },
        end: {
          label: formatMessage(locale, 'picker.time.end.label'),
        }
      },
      language: {
        label: formatMessage(locale, 'forms.language.label'),
      },
      referenceNo: {
        label: formatMessage(locale, 'forms.referenceNo.label'),
      },
      upload: false,
      notaryOffice: {
        label: formatMessage(locale, 'forms.notaryOffice.label'),
        required: true,
      },
      showReference: true,
      requestType: 'interpretationQuotes',
      requestDataGenerator: () => ({
        appointmentDate: moment(selectedDate).format('DD-MM-yyyy'),
        startTime: moment(selectedStartTime).format('HH:mm'),
        endTime: moment(selectedEndTime).format('HH:mm'),
        languageCode: selectedLanguage,
        customLanguage: language,
        referenceNo,
        notaryOffice,
        contactInfo: {
          name,
          email,
          phone,
        },
        appointmentAddress: {
          street,
          houseNo,
          postalCode,
          city,
        },
        comments
      }),
    },
    interpretationRequest: {
      title: formatMessage(locale, 'forms.interpretationRequest.title'),
      comments: formatMessage(locale, 'forms.interpretationRequest.comments'),
      confirm: formatMessage(locale, 'forms.interpretationRequest.confirm'),
      pickers: {
        date: {
          label: formatMessage(locale, 'picker.date.label'),
        },
        start: {
          label: formatMessage(locale, 'picker.time.start.label'),
        },
        end: {
          label: formatMessage(locale, 'picker.time.end.label'),
        }
      },
      language: {
        label: formatMessage(locale, 'forms.language.label'),
      },
      referenceNo: {
        label: formatMessage(locale, 'forms.referenceNo.label'),
      },
      upload: false,
      notaryOffice: {
        label: formatMessage(locale, 'forms.notaryOffice.label'),
        required: true,
      },
      showReference: true,
      requestType: 'interpretationRequest',
      requestDataGenerator: () => ({
        appointmentDate: moment(selectedDate).format('DD-MM-yyyy'),
        startTime: moment(selectedStartTime).format('HH:mm'),
        endTime: moment(selectedEndTime).format('HH:mm'),
        languageCode: selectedLanguage,
        customLanguage: language,
        referenceNo,
        notaryOffice,
        contactInfo: {
          name,
          email,
          phone,
        },
        appointmentAddress: {
          street,
          houseNo,
          postalCode,
          city,
        },
        comments
      }),
    },
    interpretationQuotesOther: {
      title: formatMessage(locale, 'forms.interpretationQuotesOther.title'),
      comments: formatMessage(locale, 'forms.interpretationQuotesOther.comments'),
      confirm: formatMessage(locale, 'forms.interpretationQuotesOther.confirm'),
      pickers: {
        date: {
          label: formatMessage(locale, 'picker.date.label'),
        },
        start: {
          label: formatMessage(locale, 'picker.time.start.label'),
        },
        end: {
          label: formatMessage(locale, 'picker.time.end.label'),
        }
      },
      language: {
        label: formatMessage(locale, 'forms.language.label'),
      },
      referenceNo: {
        label: formatMessage(locale, 'forms.referenceNo.label'),
      },
      upload: false,
      notaryOffice: {
        label: formatMessage(locale, 'forms.requester.label'),
        required: false,
      },
      showReference: false,
      requestType: 'interpretationQuotesOther',
      requestDataGenerator: () => ({
        appointmentDate: moment(selectedDate).format('DD-MM-yyyy'),
        startTime: moment(selectedStartTime).format('HH:mm'),
        endTime: moment(selectedEndTime).format('HH:mm'),
        languageCode: selectedLanguage,
        customLanguage: language,
        companyName: notaryOffice,
        contactInfo: {
          name,
          email,
          phone,
        },
        appointmentAddress: {
          street,
          houseNo,
          postalCode,
          city,
        },
        comments
      }),
    },
  };

  useEffect(() => {
    if (formMode !== 'notaryOfficeRegister') {
      checkValidity();
    } else {
      checkNotaryOfficeRegisterValidity();
    }
    setFormEnabled(formMode !== 'notaryOfficeRegister' ? interpretationValidation() : notaryRegisterValidation());
  }, [name, firstName, lastName, city, street, postalCode, selectedGender, language, email, employeeEmail, phone, employeeMobileNo, houseNo, notaryOffice, businessName, referenceNo, env, envLoading]);

  useEffect(() => {
    setFormEnabled(formMode !== 'notaryOfficeRegister' ? interpretationValidation(formContent[formMode].notaryOffice.required) : notaryRegisterValidation(formContent[formMode].business.businessName.required));
  }, [nameError, cityError, streetError, postalCodeError, languageError, emailError, phoneError, houseNoError, notaryOfficeError, referenceNoError]);


  const handleConfirm = () => {
    const requestData = formContent[formMode].requestDataGenerator();
    submitForm({
      data: requestData,
      requestType: formContent[formMode].requestType
    }, env);
  };
  return (
    <div>
      {buttons[formOrder]}
      {formMode !== 'notaryOfficeRegister' ? (
        <Dialog maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{formContent[formMode].title}</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <div className={classes.picker}>
                <MuiPickersUtilsProvider locale={nlLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    key="selected-date"
                    disablePast
                    label={formContent[formMode].pickers.date.label}
                    value={selectedDate}
                    onChange={handleDateChange}
                    format="dd-MM-yyyy"
                    cancelLabel={formatMessage(locale, 'picker.cancel.label')}
                    okLabel={formatMessage(locale, 'picker.ok.label')}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className={classes.picker}>
                <MuiPickersUtilsProvider locale={nlLocale} utils={DateFnsUtils}>
                  <TimePicker
                    key="start-time"
                    clearable
                    ampm={false}
                    label={formContent[formMode].pickers.start.label}
                    value={selectedStartTime}
                    onChange={handleStartChange}
                    minutesStep={5}
                    clearLabel={formatMessage(locale, 'picker.clear.label')}
                    cancelLabel={formatMessage(locale, 'picker.cancel.label')}
                    okLabel={formatMessage(locale, 'picker.ok.label')}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className={classes.picker}>
                <MuiPickersUtilsProvider locale={nlLocale} utils={DateFnsUtils}>
                  <TimePicker
                    key="end-time"
                    clearable
                    ampm={false}
                    label={formContent[formMode].pickers.end.label}
                    value={selectedEndTime}
                    onChange={handleEndChange}
                    minutesStep={5}
                    clearLabel={formatMessage(locale, 'picker.clear.label')}
                    cancelLabel={formatMessage(locale, 'picker.cancel.label')}
                    okLabel={formatMessage(locale, 'picker.ok.label')}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
            <Grid container spacing={1}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="translation-type">{formContent[formMode].language.label}</InputLabel>
                <Select
                  autocomplete
                  value={selectedLanguage}
                  onChange={handleSelectedLanguageChange}
                  inputProps={{
                    name: formContent[formMode].language.label,
                    id: 'translation-type',
                  }}
                >
                  {languageCodes && languageCodes.map((option, index) => (
                    <MenuItem key={index.toString()} value={option.value}>{option.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              { selectedLanguage === 'xx' && (
                <div className={classes.field}>
                  <TextField
                    value={language}
                    autoComplete="tel-extension"
                    error={languageError}
                    margin="dense"
                    id="customLanguage"
                    label={formContent[formMode].language.label}
                    type="text"
                    required
                    fullWidth
                    onChange={handleLanguageChange}
                  />
                </div>
              )}
              {formContent[formMode].showReference && (
                <div className={classes.field}>
                  <TextField
                    margin="dense"
                    id="referenceNo"
                    label={formContent[formMode].referenceNo.label}
                    type="text"
                    fullWidth
                    onChange={handleReferenceNoChange}
                  />
                </div>
              )}
            </Grid>
            <TextField
              value={notaryOffice}
              margin="dense"
              id="notaryOffice"
              label={formContent[formMode].notaryOffice.label}
              type="text"
              fullWidth
              required={formContent[formMode].notaryOffice.required}
              error={formContent[formMode].notaryOffice.required && notaryOfficeError}
              onChange={handleNotaryOfficeChange}
            />
            <Grid container spacing={1}>
              <div className={classes.longField}>
                <TextField
                  value={street}
                  autoComplete="street-address address-line1"
                  margin="dense"
                  id="street"
                  label={formatMessage(locale, 'forms.street.label')}
                  type="text"
                  required
                  fullWidth
                  error={streetError}
                  onChange={handleStreetChange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  value={houseNo}
                  autoComplete="tel-extension"
                  margin="dense"
                  id="houseNo"
                  label={formatMessage(locale, 'forms.houseNo.label')}
                  type="text"
                  required
                  fullWidth
                  error={houseNoError}
                  onChange={handleHouseNoChange}
                />
              </div>
            </Grid>
            <Grid container spacing={1}>
              <div className={classes.longField}>
                <TextField
                  value={city}
                  autoComplete="address-level2"
                  margin="dense"
                  id="city"
                  label={formatMessage(locale, 'forms.city.label')}
                  type="text"
                  required
                  fullWidth
                  error={cityError}
                  onChange={handleCityChange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  value={postalCode}
                  autoComplete="postal-code"
                  margin="dense"
                  id="postalCode"
                  label={formatMessage(locale, 'forms.postalCode.label')}
                  type="text"
                  required
                  fullWidth
                  error={postalCodeError}
                  onChange={handlePostalCodeChange}
                />
              </div>
            </Grid>
            <TextField
              value={name}
              autoComplete="name organization"
              margin="dense"
              id="contactName"
              label={formatMessage(locale, 'forms.contactName.label')}
              type="text"
              fullWidth
              required
              error={nameError}
              onChange={handleNameChange}
            />
            <TextField
              value={email}
              autoComplete="email"
              margin="dense"
              id="contactEmail"
              label={formatMessage(locale, 'forms.contactEmail.label')}
              type="email"
              fullWidth
              required
              error={emailError}
              onChange={handleEmailChange}
            />
            <TextField
              value={phone}
              autoComplete="tel"
              margin="dense"
              id="contactTelno"
              label={formatMessage(locale, 'forms.contactTelno.label')}
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              fullWidth
              required
              error={phoneError}
              onChange={handlePhoneChange}
            />
            <TextField
              value={comments}
              margin="dense"
              id="comments"
              label={formContent[formMode].comments}
              type="text"
              multiline
              fullWidth
              onChange={handleCommentsChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {formatMessage(locale, 'forms.cancel.label')}
            </Button>
            <Button onClick={handleConfirm} disabled={!isFormEnabled} color="primary">
              {formContent[formMode].confirm}
            </Button>
          </DialogActions>
          {showNotification && notificationType === 'error' && (
            <StyledNotif type={notificationType} message={notificationMessage} onClose={() => setShowNotification(false)} />
          ) }
        </Dialog>
      ) : (
        <Dialog maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{formContent[formMode].title}</DialogTitle>
          <DialogContent>
            <TextField
              value={businessName}
              margin="dense"
              id="notaryOffice"
              label={formContent[formMode].business.businessName.label}
              type="text"
              autoFocus
              fullWidth
              required={formContent[formMode].business.businessName.required}
              error={formContent[formMode].business.businessName.required && businessNameError}
              onChange={handleBusinessNameChange}
            />
            <Grid container spacing={1}>
              <div className={classes.longField}>
                <TextField
                  value={street}
                  autoComplete="street-address address-line1"
                  margin="dense"
                  id="street"
                  label={formatMessage(locale, 'forms.street-notary-office.label')}
                  type="text"
                  required
                  fullWidth
                  error={streetError}
                  onChange={handleStreetChange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  value={houseNo}
                  autoComplete="tel-extension"
                  margin="dense"
                  id="houseNo"
                  label={formatMessage(locale, 'forms.addressNo.label')}
                  type="text"
                  required
                  fullWidth
                  error={houseNoError}
                  onChange={handleHouseNoChange}
                />
              </div>
            </Grid>
            <Grid container spacing={1}>
              <div className={classes.longField}>
                <TextField
                  value={city}
                  autoComplete="address-level2"
                  margin="dense"
                  id="city"
                  label={formatMessage(locale, 'forms.city.label')}
                  type="text"
                  required
                  fullWidth
                  error={cityError}
                  onChange={handleCityChange}
                />
              </div>
              <div className={classes.field}>
                <TextField
                  value={postalCode}
                  autoComplete="postal-code"
                  margin="dense"
                  id="postalCode"
                  label={formatMessage(locale, 'forms.postalCode.label')}
                  type="text"
                  required
                  fullWidth
                  error={postalCodeError}
                  onChange={handlePostalCodeChange}
                />
              </div>
            </Grid>
            <TextField
              value={email}
              autoComplete="email"
              margin="dense"
              id="contactEmail"
              label={formatMessage(locale, 'forms.contactEmail.label')}
              type="email"
              fullWidth
              required
              error={emailError}
              onChange={handleEmailChange}
            />
            <TextField
              value={phone}
              autoComplete="tel"
              margin="dense"
              id="contactTelno"
              label={formatMessage(locale, 'forms.contactTelno.label')}
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              fullWidth
              required
              error={phoneError}
              onChange={handlePhoneChange}
            />
            <DialogTitle className={classes.innerTitle}>{formatMessage(locale, 'forms.employee-data.title')}</DialogTitle>
            <FormControl fullWidth>
              <InputLabel htmlFor="gender-select">{formatMessage(locale, 'forms.gender.label')}</InputLabel>
              <Select
                value={selectedGender}
                required
                onChange={handleGenderChange}
                inputProps={{
                  name: formatMessage(locale, 'forms.gender.label'),
                  id: 'gender-select',
                }}
              >
                {genders && genders.map((option, index) => (
                  <MenuItem key={index.toString()} value={option.value}>{option.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              value={initials}
              margin="dense"
              id="employeeInitial"
              type="text"
              label={formatMessage(locale, 'forms.initial.label')}
              fullWidth
              onChange={handleInitialChange}
            />
            <TextField
              value={firstName}
              margin="dense"
              id="firstName"
              label={formatMessage(locale, 'forms.first-name.label')}
              type="text"
              fullWidth
              required
              error={firstNameError}
              onChange={handleFirstNameChange}
            />
            <TextField
              value={lastName}
              margin="dense"
              id="lastName"
              label={formatMessage(locale, 'forms.last-name.label')}
              type="text"
              fullWidth
              required
              error={lastNameError}
              onChange={handleLastNameChange}
            />
            <TextField
              value={employeeEmail}
              autoComplete="email"
              margin="dense"
              id="employeeEmail"
              label={formatMessage(locale, 'forms.contactEmail.label')}
              type="email"
              fullWidth
              required
              error={employeeEmailError}
              onChange={handleEmployeeEmailChange}
            />
            <TextField
              value={employeeMobileNo}
              autoComplete="tel"
              margin="dense"
              id="employeeMobileNo"
              label={formatMessage(locale, 'forms.contactTelno.label')}
              type="tel"
              fullWidth
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              required
              error={employeeMobileNoError}
              onChange={handleEmployeeMobileNoChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {formatMessage(locale, 'forms.cancel.label')}
            </Button>
            <Button onClick={handleConfirm} disabled={!isFormEnabled} color="primary">
              {formContent[formMode].confirm}
            </Button>
          </DialogActions>
          {showNotification && notificationType === 'error' && (
            <StyledNotif type={notificationType} message={notificationMessage} onClose={() => setShowNotification(false)} />
          ) }
        </Dialog>
      )}
      {showNotification && notificationType !== 'error' && (
        <StyledNotif type={notificationType} message={notificationMessage} onClose={() => setShowNotification(false)} />
      ) }
    </div>
  );
}

InterpretationFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  formOrder: PropTypes.string.isRequired,
  submitForm: PropTypes.func.isRequired,
  openedForm: PropTypes.string.isRequired,
  switchForm: PropTypes.func.isRequired,
  isRequestLoading: PropTypes.bool.isRequired,
  requestMessage: PropTypes.string,
  languageCodes: PropTypes.arrayOf(PropTypes.any),
  genders: PropTypes.arrayOf(PropTypes.any),
  env: PropTypes.any,
  envLoading: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
};

InterpretationFormDialog.defaultProps = {
  requestMessage: null,
  languageCodes: [],
  env: null,
  swornCodes: null,
  genders: [],
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  submitForm: createFormSubmitRequest,
}, dispatch);
const mapStateToProps = (state) => ({
  isRequestLoading: getIsRequestLoading(state),
  requestMessage: getMessage(state),
  languageCodes: getLanguageCodes(state),
  genders: getGenders(state),
  env: getEnv(state),
  envLoading: getEnvLoading(state),
  locale: getLocale(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InterpretationFormDialog));
