import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getLocale, getParameters } from '../../redux/selectors/selector';
import formatMessage from '../helpers';
import { notaryContent } from './Sections';
import SectionRenderer from './SectionRenderer';
import styles from '../../landingStyle-jss';

let counter = 0;
function createFeatureData(icon, title, desc) {
  counter += 1;
  return {
    id: counter,
    icon,
    title,
    desc
  };
}

function NotarySection(props) {
  const { classes, slideMode } = props;
  const locale = useSelector(getLocale);
  const parameters = useSelector(getParameters);
  const [openDialog, setOpenDialog] = useState(false);
  const [openOfferForm, setOfferForm] = useState(false);
  const [openRegisterForm, setRegisterForm] = useState(false);
  const featureList = [
    createFeatureData('', formatMessage(locale, 'notaryInterpreter.title.description'), notaryContent(setOpenDialog, setOfferForm, setRegisterForm, parameters)),
  ];

  return (
    <SectionRenderer
      classes={classes}
      slideMode={slideMode}
      featureList={featureList}
      title={formatMessage(locale, 'notaryInterpreter.title.main')}
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      openRegisterForm={openRegisterForm}
      openOfferForm={openOfferForm}
      setOfferForm={setOfferForm}
      setRegisterForm={setRegisterForm}
      formOrder="notary"
    />
  );
}

NotarySection.propTypes = {
  classes: PropTypes.object.isRequired,
  slideMode: PropTypes.bool
};

NotarySection.defaultProps = {
  slideMode: false
};
export default withStyles(styles)(NotarySection);
