import React from 'react';
import PropTypes from 'prop-types';
import InterpretationFormDialog from './InterpretationFormDialog';
import TranslationFormDialog from './TranslationFormDialog';
const FormDialog = (props) => {
  const {
    formOrder,
    openedForm,
    switchForm
  } = props;


  return (
    <div>
      {formOrder === 'translations'
        ? (<TranslationFormDialog openedForm={openedForm} switchForm={switchForm} />) : (
          <InterpretationFormDialog formOrder={formOrder} openedForm={openedForm} switchForm={switchForm} />
        )}
    </div>
  );
};
FormDialog.propTypes = {
  formOrder: PropTypes.string.isRequired,
  openedForm: PropTypes.string.isRequired,
  switchForm: PropTypes.func.isRequired,
};


export default FormDialog;
